import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './appconfig.service';
import {TabViewModule, InputTextModule, SelectItem, InputSwitchModule, Message} from 'primeng/primeng';
import {MessageService} from 'primeng/api';
import {BreadcrumbService} from '../breadcrumb.service';

@Component({
  selector: 'appconfig',
  providers: [],
  templateUrl: './appconfig.component.html'
})
export class AppConfigComponent implements OnInit {

  formData: String[];
  appconfigdata: any;
  public msgs: Message[] = [];


  

  constructor(private _AppConfigService : AppConfigService, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {

    

    this.breadcrumbService.setItems([
      {label: 'blportal'},
      {label: 'Application Configuration', routerLink: ['/appconfig']}
    ]);

  }

  ngOnInit() {
    this.appconfigdata = [];
    this.loaddata()

  }

  changecheckbox(){

  }

  loaddata(){
    this._AppConfigService.getappconfigData().subscribe(data => {
      this.appconfigdata = data[0];



      //console.log(this.appconfigdata);
    });
  }

  savedata(){

    this._AppConfigService.saveconfigData(this.appconfigdata).subscribe(data => {
      this._AppConfigService.getappconfigDataInit();

      this.messageService.add({key: 'maintoast', severity:'success', summary:'Application Config Message', detail: 'Configuration saved with success'});
      //console.log(this.appconfigdata);
    });

  }

}