import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import {accountconfigService} from './accountconfig.service';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import {SelectItem, Message, MenuItem, OverlayPanel, ConfirmationService, LazyLoadEvent} from 'primeng/primeng';
import {accountconfig} from './domain/accountconfig';
import { AppConfigService } from '../appconfig/appconfig.service';
import { UserAdminService } from '../useradmin/useradmin.service';
import { Subscription } from 'rxjs/Subscription';
import {MessageService} from 'primeng/api';
@Component({
  selector: 'form-accountconfig',
  providers: [accountconfigService, DatePipe, ConfirmationService],
  templateUrl: './form-accountconfig.component.html',
  styles: [`

    :host ::ng-deep .ui-message-info{
      background-color: #77bae8;
    }

    :host ::ng-deep .ui-message-error{
      background-color: #fcb6bc;
    }

    :host ::ng-deep .ui-messages-error{
      background-color: #fcb6bc;
    }

    :host ::ng-deep .ui-message-success{
      background-color: #b2d7b8;
    }
    
    :host >>> .ui-dialog .ui-dialog-titlebar{
      background-color: #2b3135;
      color:#FFFFFF;
    }

    :host >>> .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .fa{
      color:#FFFFFF;
    }

    :host >>> body .ui-dialog .ui-dialog-content {
      padding: 0.625em 0em;
      padding-left: 1em;
    }

    :host >>> body .ui-inputtext .ng-dirty {
      border-color: #a6a6a6;
    }

    .formscroller{
      height:85vh; 
    }  

    .formcontainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    .formcontainer h2 {
      display: block;
      flex-basis: 100%;
    }

    .formitemlabel{
      flex-basis: 16%;
      margin-top:7px;
      margin-bottom:7px;
    }

    .formiteminput{
      flex-basis: 34%;
      margin-top:7px;
      margin-bottom:7px;
      padding-right:20px;
    }

    .formitemfulllength{
      flex-basis: 100%;
      margin-top:7px;
      margin-bottom:7px;
    }

    .viewstackcontainer{
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .viewstackright{
      flex-basis: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    .viewstackrightlabel{
      flex-basis: 32%;
      margin-top:7px;
      margin-bottom:7px;
      align-items: center;
    }

    .viewstackrightinput{
      flex-basis: 68%;
      margin-top:7px;
      margin-bottom:7px;
      padding-right:20px;
    }

    @media only screen and (min-width: 10px) and (max-width: 479px){

      :host ::ng-deep .ui-dialog {
        width: 100% !important;
      }

      .formscroller{
        height:75vh; 
      } 

      .formcontainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
      }

      .formitemlabel{
        flex-basis: 100%;
        margin-top:7px;
        margin-bottom:1px;
      }

      .formiteminput{
        flex-basis: 100%;
        margin-bottom:7px;
        margin-top:1px;
        padding-right:5px;
      }

      .viewstackright{
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
      }


      .viewstackrightinput{
        padding-right:5px;
      }

    }

    @media only screen and (min-width: 480px) and (max-width: 767px){

      :host ::ng-deep .ui-dialog {
        width: 100% !important;
      }

      .formscroller{
        height:60vh; 
      } 

      .formitemlabel{
        flex-basis: 32%;
        margin-top:7px;
        margin-bottom:7px;
      }

      .formiteminput{
        flex-basis: 68%;
        margin-top:7px;
        margin-bottom:7px;
        padding-right:10px;
      }

      .viewstackright{
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
      }

      .viewstackrightinput{
        padding-right:10px;
      }

    }

    @media only screen and (min-width: 768px) and (max-width: 991px){


    }

    @media only screen and (min-width: 992px) and (max-width: 1999px){


    }


  `]
})
export class accountconfigFormComponent implements OnInit, OnChanges {
  public accountconfigdata : accountconfig[];
  public loading : boolean = false;
  public displayaddedit : boolean = false;
  public msgs: Message[] = [];
  myform: FormGroup;
  submitted: boolean;
  public formData: any;
  public formactivemode: String;
  public formactiveid: String;
  @Input() defaultcreatevalues:any = [];
  @Output() formClosed = new EventEmitter();
  public formLabel_button: string = "";
  items: MenuItem[];
  first: number;
  
  
  
  

  public userinfo: any;
  public appconfig: any = [];

  subscriptionislogged: Subscription;
  subscriptionappconfig: Subscription;

  /*
  selectedaccountconfig : accountconfig= {
    aconf_id: 1,
  aconf_cukey: '',
  aconf_username: '',
  aconf_companyname: '',
  };
  */


  //selectedaccountconfig.aconf_id = 1;

  

  constructor(private _accountconfigService:accountconfigService, public datepipe: DatePipe, private _ConfirmationService:ConfirmationService, private fb: FormBuilder, private _UserAdminService : UserAdminService, private _AppConfigService: AppConfigService, private messageService: MessageService) {
    this.userinfo = [];
    this.userinfo.mysecurity = [];

    
      this.userinfo.mysecurity.sec_admin = [];
      this.userinfo.mysecurity.sec_orderentry = [];
      this.userinfo.mysecurity.sec_transactionlog = [];



    this.appconfig = [];

  }

  ngOnInit() {

    this.subscriptionislogged = this._UserAdminService.isloggedobs().subscribe(
      userinfo => {
        this.userinfo = userinfo;

        //console.log(userinfo);
      }
    );

    this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
      appconfig => {
      this.appconfig = appconfig[0];
      //console.log(this.appconfig);
      }
    );

    //this._UserAdminService.refreshisloggedobs();

    this.formData = [];


    this.myform = this.fb.group({
      'aconf_id': new FormControl('')
      
    ,'aconf_cukey': new FormControl({value: '', disabled: this.loading}, Validators.required)
    ,'aconf_username': new FormControl({value: '', disabled: this.loading}, Validators.required)
    ,'aconf_companyname': new FormControl({value: '', disabled: this.loading}, Validators.required)
      ,'aconf_addeddate': new FormControl({value: ''})
      ,'aconf_addedby': new FormControl({value: ''})
      ,'aconf_name_addedby': new FormControl({value: ''})
      ,'aconf_modifiedby': new FormControl({value: ''})
      ,'aconf_name_modifiedby': new FormControl({value: ''})
      ,'aconf_modifieddate': new FormControl({value: ''})
    });

    

  }

  ngOnChanges(...args: any[]){

  }

  

  formshow(mode, id = null){

    this.formactivemode = mode;
    this.formactiveid = id;
    this.displayaddedit = true;

    if(mode == 'modify'){
      this.formLabel_button = 'Save Modification';
    }
    else if(mode == 'copy' || mode == 'create'){
      this.formLabel_button = 'Create Record';
    }

    if(mode == 'modify' || mode == 'copy'){

      this.loading = true;
      this._accountconfigService.getaccountconfigSingleData(id).subscribe(data => {

        
        
        

        this.myform.setValue(data[0]);

        if(mode == 'copy'){
          
        }  
        
        

        setTimeout(() => {


          


          

          //console.log(data);

          
          this.loading = false;
          //console.log('AAAAAAA', this.formData);
        }, 1500);

      },
      error => {
        //console.log(error); // gives the object object
      });
    }
    
    else if(mode == 'create'){
      this.formData = [];

      if(!this.defaultcreatevalues){
        this.defaultcreatevalues = [];
      }

      setTimeout(() => {
        

        
    if(this.defaultcreatevalues.aconf_cukey){
      this.myform.controls['aconf_cukey'].setValue(this.defaultcreatevalues.aconf_cukey);
    }
    else{
      this.myform.controls['aconf_cukey'].setValue('');
    }
    if(this.defaultcreatevalues.aconf_username){
      this.myform.controls['aconf_username'].setValue(this.defaultcreatevalues.aconf_username);
    }
    else{
      this.myform.controls['aconf_username'].setValue('');
    }
    if(this.defaultcreatevalues.aconf_companyname){
      this.myform.controls['aconf_companyname'].setValue(this.defaultcreatevalues.aconf_companyname);
    }
    else{
      this.myform.controls['aconf_companyname'].setValue('');
    }
        
      }, 500);
    }
  }

  formclose(id = null){

    this.myform.reset();
    
    this.displayaddedit = false;
    this.formClosed.emit(id);

  }

  formsubmit(value: any){
    //console.log(value);
    value.id = this.formactiveid;
    if(this.formactivemode == 'modify'){
      //alert('modify');
      
      this.loading = true;

      this._accountconfigService.updateaccountconfigData(value).subscribe(data => {
        this.messageService.add({key: 'maintoast', severity:'success', summary:'Account config Message', detail:'Record modified with success'});

        

        

        setTimeout(() => {
          this.loading = false;
          this.formclose();
        }, 1000);

        //console.log(data);

      },
      error => {
        this.messageService.add({key: 'maintoast', severity:'error', summary:'Account config Message', detail:'Error modifying record'});
        //console.log(error);
        this.formclose();
      });
    }
    else if(this.formactivemode == 'create' || this.formactivemode == 'copy'){
      //alert('modify');
      
      setTimeout(() => {
        this.loading = true;
      }, 1000);
      this._accountconfigService.addaccountconfigData(value).subscribe(data => {

        //console.log(data);
        this.formactiveid = data.id;

        setTimeout(() => {
        

          this.loading = false;

          if(data.success){
            this.messageService.add({key: 'maintoast', severity:'success', summary:'Account config Message', detail:'Record created with success'});
            

            
            this.formclose(data.id);
          }
          else{
            this.messageService.add({key: 'maintoast', severity:'error', summary:'Account config Message', detail:'Error creating record'});
            alert(data.error);
          }
        }, 2000);
      },
      error => {
        this.messageService.add({key: 'maintoast', severity:'error', summary:'Account config Message', detail:'Error creating record'});
        //console.log(error);
        this.formclose();
      });
    }
  }


}