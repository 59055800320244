import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import {OrderEntryService} from './OrderEntry.service';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import {SelectItem, Message, MenuItem, OverlayPanel, ConfirmationService, LazyLoadEvent} from 'primeng/primeng';
import {ediorderheader} from './domain/ediorderheader';
import { AppConfigService } from '../appconfig/appconfig.service';
import { UserAdminService } from '../useradmin/useradmin.service';
import { Subscription } from 'rxjs/Subscription';
import {MessageService} from 'primeng/api';
//import { exists } from 'fs';

@Component({
  selector: 'formorderentry',
  providers: [OrderEntryService, DatePipe, ConfirmationService],
  templateUrl: './formOrderEntry.component.html',
  styles: [`

    :host ::ng-deep .ui-message-info{
      background-color: #77bae8;
    }

    :host ::ng-deep .ui-message-error{
      background-color: #fcb6bc;
    }

    :host ::ng-deep .ui-messages-error{
      background-color: #fcb6bc;
    }

    :host ::ng-deep .ui-message-success{
      background-color: #b2d7b8;
    }
    
    :host >>> .ui-dialog .ui-dialog-titlebar{
      background-color: #2b3135;
      color:#FFFFFF;
    }

    :host >>> .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .fa{
      color:#FFFFFF;
    }

    :host >>> body .ui-dialog .ui-dialog-content {
      padding: 0.625em 0em;
      padding-left: 1em;
    }

    :host >>> body .ui-inputtext .ng-dirty {
      border-color: #a6a6a6;
    }

    .formscroller{
      height:85vh; 
    }  

    .formcontainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    .formcontainer h2 {
      display: block;
      flex-basis: 100%;
    }

    .formitemlabel{
      flex-basis: 16%;
      margin-top:7px;
      margin-bottom:7px;
    }

    .formiteminput{
      flex-basis: 34%;
      margin-top:7px;
      margin-bottom:7px;
      padding-right:20px;
    }

    .formitemfulllength{
      flex-basis: 100%;
      margin-top:7px;
      margin-bottom:7px;
    }

    .viewstackcontainer{
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .viewstackright{
      flex-basis: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    .viewstackrightlabel{
      flex-basis: 32%;
      margin-top:7px;
      margin-bottom:7px;
      align-items: center;
    }

    .viewstackrightinput{
      flex-basis: 68%;
      margin-top:7px;
      margin-bottom:7px;
      padding-right:20px;
    }

    @media only screen and (min-width: 10px) and (max-width: 479px){

      :host ::ng-deep .ui-dialog {
        width: 100% !important;
      }

      .formscroller{
        height:75vh; 
      } 

      .formcontainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
      }

      .formitemlabel{
        flex-basis: 100%;
        margin-top:7px;
        margin-bottom:1px;
      }

      .formiteminput{
        flex-basis: 100%;
        margin-bottom:7px;
        margin-top:1px;
        padding-right:5px;
      }

      .viewstackright{
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
      }


      .viewstackrightinput{
        padding-right:5px;
      }

    }

    @media only screen and (min-width: 480px) and (max-width: 767px){

      :host ::ng-deep .ui-dialog {
        width: 100% !important;
      }

      .formscroller{
        height:60vh; 
      } 

      .formitemlabel{
        flex-basis: 32%;
        margin-top:7px;
        margin-bottom:7px;
      }

      .formiteminput{
        flex-basis: 68%;
        margin-top:7px;
        margin-bottom:7px;
        padding-right:10px;
      }

      .viewstackright{
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
      }

      .viewstackrightinput{
        padding-right:10px;
      }

    }

    @media only screen and (min-width: 768px) and (max-width: 991px){


    }

    @media only screen and (min-width: 992px) and (max-width: 1999px){


    }


  `]
})
export class OrderEntryFormComponent implements OnInit, OnChanges {
  public ediorderheaderdata : ediorderheader[];
  public loading : boolean = false;
  public displayaddedit : boolean = false;
  public msgs: Message[] = [];
  myform: FormGroup;
  submitted: boolean;
  public formData: any;
  public formactivemode: String;
  public formactiveid: String;
  @Input() defaultcreatevalues:any = [];
  @Output() formClosed = new EventEmitter();
  public formLabel_button: string = "";
  items: MenuItem[];
  first: number;

  public sendtobeelinearr: SelectItem[];
  
  @ViewChild('productlistcmp', {static: false}) productlistComponent;
  
  

  public userinfo: any;
  public appconfig: any = [];
  public detailData: any = [];

  subscriptionislogged: Subscription;
  subscriptionappconfig: Subscription;

  /*
  selectedediorderheader : ediorderheader= {
    eoh_id: 1,
  eoh_clientref: '',
  eoh_shiptoref: '',
  eoh_status: '',
  eoh_shiptoname: '',
  eoh_shiptoaddress1: '',
  eoh_shiptoaddress2: '',
  eoh_shiptocity: '',
  eoh_shiptostate: '',
  eoh_shiptozipcode: '',
  eoh_shiptocountry: '',
  };
  */


  //selectedediorderheader.eoh_id = 1;

  

  constructor(private _OrderEntryService:OrderEntryService, public datepipe: DatePipe, private _ConfirmationService:ConfirmationService, private fb: FormBuilder, private _UserAdminService : UserAdminService, private _AppConfigService: AppConfigService, private messageService: MessageService) {
    this.userinfo = [];
    this.userinfo.mysecurity = []; 
    this.userinfo.mysecurity.sec_admin = [];

    this.appconfig = [];

    this.sendtobeelinearr = [];
    this.sendtobeelinearr.push({label: 'Send order to Beeline for processing', value: 'Now'});
    this.sendtobeelinearr.push({label: 'Hold order and send later when ready', value: 'Later'});

  }

  ngOnInit() {

    this.subscriptionislogged = this._UserAdminService.isloggedobs().subscribe(
      userinfo => {
        this.userinfo = userinfo;

        //console.log(userinfo);
      }
    );

    this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
      appconfig => {
      this.appconfig = appconfig[0];
      //console.log(this.appconfig);
      }
    );

    //this._UserAdminService.refreshisloggedobs();

    this.formData = [];


    this.myform = this.fb.group({
      'eoh_id': new FormControl('')
      
      ,'eoh_clientref': new FormControl({value: '', disabled: this.loading}, Validators.required)
      ,'eoh_shiptoref': new FormControl({value: '', disabled: this.loading})
      ,'eoh_status': new FormControl({value: '', disabled: this.loading})
      ,'eoh_shiptoname': new FormControl({value: '', disabled: this.loading}, Validators.required)
      ,'eoh_shiptoaddress1': new FormControl({value: '', disabled: this.loading}, Validators.required)
      ,'eoh_shiptoaddress2': new FormControl({value: '', disabled: this.loading})
      ,'eoh_shiptocity': new FormControl({value: '', disabled: this.loading}, Validators.required)
      ,'eoh_shiptostate': new FormControl({value: '', disabled: this.loading}, Validators.required)
      ,'eoh_shiptozipcode': new FormControl({value: '', disabled: this.loading}, Validators.required)
      ,'eoh_shiptocountry': new FormControl({value: '', disabled: this.loading}, Validators.required)
      ,'eoh_sendtobeeline': new FormControl({value: 'Now', disabled: this.loading})
      ,'eoh_addeddate': new FormControl({value: ''})
      ,'eoh_addedby': new FormControl({value: ''})
      ,'eoh_name_addedby': new FormControl({value: ''})
      ,'eoh_modifiedby': new FormControl({value: ''})
      ,'eoh_name_modifiedby': new FormControl({value: ''})
      ,'eoh_modifieddate': new FormControl({value: ''})
    });

    

  }

  ngOnChanges(...args: any[]){

  }

  

  formshow(mode, id = null){

    this.formactivemode = mode;
    this.formactiveid = id;
    this.displayaddedit = true;

    this.productlistComponent.cleardata();

    if(mode == 'modify'){
      this.formLabel_button = 'Save Modification';
    }
    else if(mode == 'copy' || mode == 'create'){
      this.formLabel_button = 'Create Record';
    }

    if(mode == 'modify' || mode == 'copy'){

      this.loading = true;
      this._OrderEntryService.getOrderEntrySingleData(id).subscribe(data => {

        //data[0].sendtobeeline = 'Later';

        this.myform.setValue(data[0]);

        if(mode == 'copy'){
          this.myform.controls['eoh_status'].setValue('Hold - waiting for user approval');
          this.myform.controls['eoh_sendtobeeline'].setValue('Later');
        }  
        
        

        setTimeout(() => {


          
          this.productlistComponent.getdata();

          

          //console.log(data);

          
          this.loading = false;
          //console.log('AAAAAAA', this.formData);
        }, 500);

      },
      error => {
        //console.log(error); // gives the object object
      });
    }
    
    else if(mode == 'create'){
      this.formData = [];

      if(!this.defaultcreatevalues){
        this.defaultcreatevalues = [];
      }

      setTimeout(() => {
        
        
    if(this.defaultcreatevalues.eoh_clientref){
      this.myform.controls['eoh_clientref'].setValue(this.defaultcreatevalues.eoh_clientref);
    }
    else{
      this.myform.controls['eoh_clientref'].setValue('');
    }
    if(this.defaultcreatevalues.eoh_shiptoref){
      this.myform.controls['eoh_shiptoref'].setValue(this.defaultcreatevalues.eoh_shiptoref);
    }
    else{
      this.myform.controls['eoh_shiptoref'].setValue('');
    }
    if(this.defaultcreatevalues.eoh_status){
      this.myform.controls['eoh_status'].setValue(this.defaultcreatevalues.eoh_status);
    }
    else{
      this.myform.controls['eoh_status'].setValue('Hold - waiting for user approval');
    }
    if(this.defaultcreatevalues.eoh_shiptoname){
      this.myform.controls['eoh_shiptoname'].setValue(this.defaultcreatevalues.eoh_shiptoname);
    }
    else{
      this.myform.controls['eoh_shiptoname'].setValue('');
    }
    if(this.defaultcreatevalues.eoh_shiptoaddress1){
      this.myform.controls['eoh_shiptoaddress1'].setValue(this.defaultcreatevalues.eoh_shiptoaddress1);
    }
    else{
      this.myform.controls['eoh_shiptoaddress1'].setValue('');
    }
    if(this.defaultcreatevalues.eoh_shiptoaddress2){
      this.myform.controls['eoh_shiptoaddress2'].setValue(this.defaultcreatevalues.eoh_shiptoaddress2);
    }
    else{
      this.myform.controls['eoh_shiptoaddress2'].setValue('');
    }
    if(this.defaultcreatevalues.eoh_shiptocity){
      this.myform.controls['eoh_shiptocity'].setValue(this.defaultcreatevalues.eoh_shiptocity);
    }
    else{
      this.myform.controls['eoh_shiptocity'].setValue('');
    }
    if(this.defaultcreatevalues.eoh_shiptostate){
      this.myform.controls['eoh_shiptostate'].setValue(this.defaultcreatevalues.eoh_shiptostate);
    }
    else{
      this.myform.controls['eoh_shiptostate'].setValue('');
    }
    if(this.defaultcreatevalues.eoh_shiptozipcode){
      this.myform.controls['eoh_shiptozipcode'].setValue(this.defaultcreatevalues.eoh_shiptozipcode);
    }
    else{
      this.myform.controls['eoh_shiptozipcode'].setValue('');
    }
    if(this.defaultcreatevalues.eoh_shiptocountry){
      this.myform.controls['eoh_shiptocountry'].setValue(this.defaultcreatevalues.eoh_shiptocountry);
    }
    else{
      this.myform.controls['eoh_shiptocountry'].setValue('US');
    }
        
    this.myform.controls['eoh_sendtobeeline'].setValue('Now');

      }, 500);
    }
  }

  formclose(id = null){

    this.myform.reset();
    
    this.displayaddedit = false;
    this.formClosed.emit(id);

  }

  formsubmit(value: any){
    //console.log(value);
    value.id = this.formactiveid;

    if(this.myform.controls['eoh_status'].value != 'Hold - waiting for user approval'){
      alert('This order is processing and cannot be modified, please contact Beeline in order to change it'); 
    }
    else if(this.productlistComponent.orderlistdata.length < 1){
      alert('You must at least have one item in order to create/save your order'); 
    }
    else if(this.formactivemode == 'modify'){
      //alert('modify');
      
      this.loading = true;

      this._OrderEntryService.updateOrderEntryData(value).subscribe(data => {
        this.messageService.add({key: 'maintoast', severity:'success', summary:'Order Message', detail:'Record modified with success'});

        this.productlistComponent.save();
        
        setTimeout(() => {
          this.loading = false;
          this.formclose();
        }, 1000);

        //console.log(data);

      },
      error => {
        this.messageService.add({key: 'maintoast', severity:'error', summary:'Order Message', detail:'Error modifying record'});
        //console.log(error);
        this.formclose();
      });

    }
    else if(this.formactivemode == 'create' || this.formactivemode == 'copy'){
      //alert('modify');
      
      setTimeout(() => {
        this.loading = true;
      }, 1000);
      this._OrderEntryService.addOrderEntryData(value).subscribe(data => {

        //console.log(data);
        this.formactiveid = data.id;

        

        setTimeout(() => {
        
          this.productlistComponent.save();
          this.loading = false;

          if(data.success){
            this.messageService.add({key: 'maintoast', severity:'success', summary:'Order Message', detail:'Record created with success'});          
            this.formclose();
          }
          else{
            this.messageService.add({key: 'maintoast', severity:'error', summary:'Order Message', detail:'Error creating record'});
            alert(data.error);
          }
        }, 1000);
      },
      error => {
        this.messageService.add({key: 'maintoast', severity:'error', summary:'Order Message', detail:'Error creating record'});
        //console.log(error);
        this.formclose();
      });
    }
  }



}