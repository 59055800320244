import {Injectable} from '@angular/core';
//import {Http, Headers, URLSearchParams} from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserAdminService {
    public result: any;
    public hash: any;

    private userinforesult:any = [];
    private securitysubject = new BehaviorSubject<any>(this.userinforesult);

    constructor(private http: HttpClient){
        //console.log('User Admin Service Initialized...');

        this.userinforesult = [];
        this.userinforesult.islogged = false;
        this.userinforesult.mysecurity = [];

        
    this.userinforesult.mysecurity.sec_admin = false
    this.userinforesult.mysecurity.sec_orderentry = false
    this.userinforesult.mysecurity.sec_transactionlog = false

        this.securitysubject = new BehaviorSubject<any>(this.userinforesult);

        this.getisloggedobs();

    }

    islogged(){
        let data = new HttpParams();
        //.map(res => res.json())

        return this.http.post<any>('/useradmin_islogged', data, { withCredentials: true })

            .catch(err =>  {return Observable.throw(err);});

    }

    checkaccess(accesstype, accesslevel){
      let data = new HttpParams();
      //.map(res => res.json())


      return this.http.post('/useradmin_islogged', data, { withCredentials: true })

          .catch(err =>  {return Observable.throw(err);});
    }

    getisloggedobs(){
      let data = new HttpParams();
      //.map(res => res.json())

      this.http.post('/useradmin_islogged', data, { withCredentials: true })
        .catch(err =>  {return Observable.throw(err);})
        .subscribe(
          data => {
            this.userinforesult = data;
            this.refreshisloggedobs();
          },
          error => {
            //console.log(error.toString());
          }
        );
    }

    refreshisloggedobs(){
      this.securitysubject.next(this.userinforesult);
    }

    isloggedobs(): Observable<any> {
      return this.securitysubject.asObservable();
    }

    getuseradminData(formData){
      let data = new HttpParams()
      .set('mode', formData.mode);

        return this.http.post('/useradmin_getall', data)

           .catch(err =>  {return Observable.throw(err);});

    }

    adduseradminData(formData){
      let data = new HttpParams()
      .set('email', formData.email)
      .set('password', formData.password)
      .set('repeatpassword', formData.repeatpassword)
      .set('firstname', formData.firstname)
      .set('lastname', formData.lastname)
      .set('homepage', formData.homepage)

      .set('sec_admin', formData.sec_admin)
      .set('sec_orderentry', formData.sec_orderentry)
      .set('sec_transactionlog', formData.sec_transactionlog)
      .set('cukey', formData.cukey)
      .set('customername', formData.customername)
      .set('system', formData.system)
      .set('isactive', formData.isactive);

        return this.http.post<any>('/useradmin_register', data)

            .catch(err =>  {return Observable.throw(err);});

    }

    activateuseradminData(formData){
        let data = new HttpParams()
        .set('key', formData.key);

        return this.http.post('/useradmin_activate', data)

            .catch(err =>  {return Observable.throw(err);});
    }

    login(formData:any){
        let data = new HttpParams()
        .set('email', formData.email)
        .set('password', formData.password);

        return this.http.post<any>('/useradmin_login', data, { withCredentials: true })
            .catch(err =>  {return Observable.throw(err);});
    }

    logout(){
      let data = new HttpParams();

      return this.http.post('/useradmin_logout', data, { withCredentials: true })
        .catch(err =>  {return Observable.throw(err);});
    }


    getuseradminSingleData(id){
      let data = new HttpParams();

        return this.http.post('/useradmin_getsingle/'+id, data)

            .catch(err =>  {return Observable.throw(err);});

    }

    updateuseradminData(formData){
        let data = new HttpParams()
          .set('id', formData.id)
          .set('firstname', formData.firstname)
          .set('lastname', formData.lastname)
          .set('password', formData.password)
          .set('changepassword', formData.changepassword)
          .set('homepage', formData.homepage)

      .set('sec_admin', formData.sec_admin)
      .set('sec_orderentry', formData.sec_orderentry)
      .set('sec_transactionlog', formData.sec_transactionlog)
      .set('cukey', formData.cukey)
      .set('customername', formData.customername)
      .set('system', formData.system)
          .set('isactive', formData.isactive);


        //.map(res => res.json())

        return this.http.put('/useradmin_update', data)

            .catch(err =>  {return Observable.throw(err);});
    }

    deleteuser(id){
      let data = new HttpParams()
      .set('id', id);

      return this.http.post('/useradmin_delete', data)

          .catch(err =>  {return Observable.throw(err);});
    }


    requestresetpass(formData){
      let data = new HttpParams()
      .set('email', formData.email);

      return this.http.post<any>('/useradmin_requestresetpass', data)

          .catch(err =>  {return Observable.throw(err);});
    }

    resetpass(formData){
      let data = new HttpParams()
      .set('key', formData.key)
      .set('email', formData.email)
      .set('password', formData.password)
      .set('repeatpassword', formData.repeatpassword);

      //console.log(formData.password, formData.repeatpassword);

      return this.http.post('/useradmin_resetpass', data)

          .catch(err =>  {return Observable.throw(err);});
    }

    getrequest(key, type){
      let data = new HttpParams()
      .set('key', key)
      .set('type', type);

      return this.http.post<any>('/useradmin_getrequest', data)

         .catch(err =>  {return Observable.throw(err);});
    }

}