import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { Subscription } from 'rxjs/Subscription';
import { AppConfigService } from './appconfig/appconfig.service';
import { UserAdminService } from './useradmin/useradmin.service';
import {Message} from 'primeng/primeng';
import {Router} from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    public userinfo: any = [];
    public appconfig: any = [];
    public loading : boolean = false;
    public msgs: Message[] = [];

    subscriptionislogged: Subscription;
    subscriptionappconfig: Subscription;

    constructor(public app: AppComponent, private _UserAdminService : UserAdminService, private _AppConfigService: AppConfigService, private router: Router) {

    }


    ngOnInit() {

      //alert('hello');

      this._AppConfigService.getappconfigData();

      this.subscriptionislogged = this._UserAdminService.isloggedobs().subscribe(
        userinfo => {
          this.userinfo = userinfo;

          console.log(userinfo);
        }
      );

      this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
        appconfig => {
  //          alert('config');
          this.appconfig = appconfig[0];
          console.log(this.appconfig);
        }
      );

    }


    logout(){

      setTimeout(() => {
        this.msgs.push({severity: 'info', summary: 'Logout', detail: 'Logout Success'});
        this.loading = true;
      }, 100);
      this._UserAdminService.logout().subscribe(
        data => {

          this._UserAdminService.getisloggedobs();

          this.router.navigate(['/login']);

          console.log(data);

        },
        error => {
          console.log(error.toString());
        }
      );
    }

}
