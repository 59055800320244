import { Component, OnInit } from '@angular/core';
import { UserAdminService } from '../useradmin/useradmin.service';
import {Message} from 'primeng/primeng';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AppConfigService } from '../appconfig/appconfig.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
   styles: [`

    :host >>> .ui-growl-message-error {
      background-color: #FF0000;
    }

  `]
})
export class LoginComponent implements OnInit {
  public formData: any;
  public logindata: any;
  public userdata: any;
  public loginmode: String;
  public loading : boolean = false;
  public msgs: Message[] = [];
  public returnUrl: String;
  private appconfig: any;

  subscriptionappconfig: Subscription;

  constructor(private _UserAdminService : UserAdminService, private route: ActivatedRoute, private router: Router, private _AppConfigService: AppConfigService) {
    this.formData = [];
    this.formData.isactive = 0;
    //this.formData.password = 'BDhdy7j6';

    this.loginmode = 'login';

    this.appconfig = [];

  }


  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.islogged();


    this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
      appconfig => {
//          alert('config');
      this.appconfig = appconfig[0];
      console.log(this.appconfig);
      }
    );

  }

  changemode(mode){
    this.formData.password = '';
    this.formData.repeatpassword = '';

    setTimeout(() => {
    this.loginmode = mode;
    }, 100);
  }

  login(){
    //setTimeout(() => {
      //this.msgs.push({severity: 'info', summary: 'Login Message', detail: 'Loading Data'});
      this.loading = true;
    //}, 100);
    this._UserAdminService.login(this.formData).subscribe(data => {
      this.logindata = data;
      this.loading = false;

      console.log(this.logindata);

      if(data.error){
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Logon Failed', detail: data.message});
      }
      else{
        this.msgs = [];
        this.msgs.push({severity: 'success', summary: 'Logon Success', detail: 'Redirecting to last accessed url'});
        if(this.returnUrl != '/' && this.returnUrl != '/login'){
          this.router.navigate([this.returnUrl]);
        }
        else{
          this._UserAdminService.getisloggedobs();
          this.islogged();
          if(this.logindata.homepage != ''){
            this.router.navigate(['/'+this.logindata.homepage]);
          }
          else{
            this.router.navigate(['/']);
          }

        }
        //alert(this.returnUrl);
      }



      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

  register(){
    //this.msgs.push({severity: 'info', summary: 'Login Message', detail: 'Loading Data'});
    this.loading = true;
    this._UserAdminService.adduseradminData(this.formData).subscribe(data => {

      if(data.error){
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Account creation error', detail: data.message});
      }
      else{
        this.islogged();
      }
      this.loading = false;
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

  forgotpassword(){
    this.loading = true;
    this._UserAdminService.requestresetpass(this.formData).subscribe(data => {

      if(data.error){
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Password reset error', detail: data.message});
      }
      else{
        this.msgs = [];
        this.msgs.push({severity: 'success', summary: 'Password reset success', detail: data.message});
      }
      this.loading = false;
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

  islogged(){
    this._UserAdminService.islogged().subscribe(data => {
      this.userdata = data;

      if(!data.islogged){
        this.changemode('login');
        //this.loginmode = 'login';
      }
      else{
        this.changemode('loggedon');
        //this.loginmode = 'loggedon';
      }

      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

  logout(){

    setTimeout(() => {
      //this.msgs.push({severity: 'info', summary: 'Login Message', detail: 'Loading Data'});
      this.loading = true;
    }, 100);
    this._UserAdminService.logout().subscribe(data => {
      this.islogged();
      console.log(data);
      this.loading = false;
    },
    error => {
      console.log(error); // gives the object object
    });

  }

  onenterkey(){
    if(this.loginmode == 'login'){
      this.login();
    }
    else if(this.loginmode == 'register'){
      this.register();
    }
    if(this.loginmode == 'forgotpassword'){
      this.forgotpassword();
    }

  }
}
