import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpRequest, HTTP_INTERCEPTORS  } from '@angular/common/http';
//import {HttpModule, XHRBackend}    from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routes';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/observable/throw';
import { FileSelectDirective } from 'ng2-file-upload';

//import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
//import { CarouselModule } from 'primeng/carousel';
//import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
//import { ChipsModule } from 'primeng/chips';
//import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
//import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
//import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
//import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
//import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
//import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
//import { OrderListModule } from 'primeng/orderlist';
//import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
//import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
//import { RatingModule } from 'primeng/rating';
//import { ScheduleModule } from 'primeng/schedule';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
//import { SlideMenuModule } from 'primeng/slidemenu';
//import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
//import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
//import { TerminalModule } from 'primeng/terminal';
//import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
//import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
//import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

import { AppComponent } from './app.component';
import { AppRightPanelComponent } from './app.rightpanel.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
//import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { EventService } from './demo/service/eventservice';
import { NodeService } from './demo/service/nodeservice';
import { BreadcrumbService } from './breadcrumb.service';
import {MessageService} from 'primeng/api';;


import { UserAdminFormComponent} from './useradmin/form-useradmin.component';
//CUSTOM MODULE IMPORT

        import { InvDashboardComponent } from './InvDashboard/InvDashboard.component';
          import { InvDashboardService } from './InvDashboard/InvDashboard.service';
        import { OrderEntryComponent } from './OrderEntry/OrderEntry.component';
          import { OrderEntryService } from './OrderEntry/OrderEntry.service';
  import { portalinventoryComponent } from './portalinventory/datalist-portalinventory.component'; //MENU DATALIST
  import { portalinventoryFormComponent } from './portalinventory/form-portalinventory.component'; //MENU FORM
          import { portalinventoryService } from './portalinventory/portalinventory.service';
  import { accountconfigComponent } from './accountconfig/datalist-accountconfig.component'; //MENU DATALIST
  import { accountconfigFormComponent } from './accountconfig/form-accountconfig.component'; //MENU FORM
          import { accountconfigService } from './accountconfig/accountconfig.service';
        import { TransactionLogComponent } from './StockTransactionLog/StockTransactionLog.component';
          import { TransactionLogService } from './StockTransactionLog/StockTransactionLog.service';
  import { portalitemmasterComponent } from './portalitemmaster/datalist-portalitemmaster.component'; //MENU DATALIST
  import { portalitemmasterFormComponent } from './portalitemmaster/form-portalitemmaster.component'; //MENU FORM
          import { portalitemmasterService } from './portalitemmaster/portalitemmaster.service';
  import { ediorderheaderComponent } from './ediorderheader/datalist-ediorderheader.component'; //MENU DATALIST
  import { ediorderheaderFormComponent } from './ediorderheader/form-ediorderheader.component'; //MENU FORM
          import { ediorderheaderService } from './ediorderheader/ediorderheader.service';
        import { AppConfigComponent } from './appconfig/appconfig.component';
          import { AppConfigService } from './appconfig/appconfig.service';
        import { UserAdminComponent } from './useradmin/useradmin.component';
          import { UserAdminService } from './useradmin/useradmin.service';
        import { LoginComponent } from './login/login.component';
        import { UserAdminActComponent } from './useradminactivate/useradminactivate.component';
        import { UserAdminResetPassComponent } from './useradminresetpass/useradminresetpass.component';
        import { UserAdminReqResetPassComponent } from './useradminreqresetpass/useradminreqresetpass.component';
        import { MyProfileComponent } from './myprofile/myprofile.component';

      import {portalinventoryMenuButtonComponent} from './portalinventory/menubutton-portalinventory.component';
      import {accountconfigMenuButtonComponent} from './accountconfig/menubutton-accountconfig.component';
      import {portalitemmasterMenuButtonComponent} from './portalitemmaster/menubutton-portalitemmaster.component';

import { OrderEntryFormComponent} from './OrderEntry/formOrderEntry.component';
import { portalitemmasterParrentComponent} from './OrderEntry/parrent-portalitemmaster.component';
import { productlistComponent} from './OrderEntry/formOrderEntry_ProductList.component';


//import {ObjectToArrayPipe} from './_pipes/objecttoarray.pipe';

import {AuthGuard} from './_guards/auth.guard';
import {BaseUrlInterceptor} from './_services/baseurlv4.service';
import {AutofocusDirective} from './_directives/autofocus.directive';
//import {ApiXHRBackend} from './_services/baseurl.service';





//AccordionModule,
//CarouselModule,
//ChartModule,
//ChipsModule,
//CodeHighlighterModule,
//EditorModule,
//GalleriaModule,
//InplaceModule,
//LightboxModule,
//
//MegaMenuModule,
//OrderListModule,
//OrganizationChartModule,
//ProgressBarModule,
//RatingModule,
//ScheduleModule,
//SlideMenuModule,
//SliderModule,
//StepsModule,
//TerminalModule,
//TieredMenuModule,
//ToolbarModule,
//TreeModule,



//DashboardDemoComponent,

@NgModule({
    imports: [
      BrowserModule,
      FormsModule,
      AppRoutes,
      HttpClientModule,
      BrowserAnimationsModule,
      AutoCompleteModule,
      BreadcrumbModule,
      ButtonModule,
      CalendarModule,
      CheckboxModule,
      ConfirmDialogModule,
      ColorPickerModule,
      ContextMenuModule,
      DataViewModule,
      DialogModule,
      DropdownModule,
      FieldsetModule,
      FileUploadModule,
      GrowlModule,
      InputMaskModule,
      InputSwitchModule,
      InputTextModule,
      InputTextareaModule,
      ListboxModule,
      MenuModule,
      MenubarModule,
      MessagesModule,
      MessageModule,
      MultiSelectModule,
      OverlayPanelModule,
      PaginatorModule,
      PanelModule,
      PanelMenuModule,
      PasswordModule,
      PickListModule,
      RadioButtonModule,
      ScrollPanelModule,
      SelectButtonModule,
      SpinnerModule,
      SplitButtonModule,
      TableModule,
      TabMenuModule,
      TabViewModule,
      ToggleButtonModule,
      TooltipModule,
      ReactiveFormsModule,
      ToastModule,
      TreeTableModule
  
    ],
    declarations: [
        AutofocusDirective,
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent,
        AppRightPanelComponent,
        FileSelectDirective,


      UserAdminFormComponent,
        InvDashboardComponent,
        OrderEntryComponent,
          portalinventoryComponent,
          portalinventoryFormComponent,
          accountconfigComponent,
          accountconfigFormComponent,
        TransactionLogComponent,
          portalitemmasterComponent,
          portalitemmasterFormComponent,
          ediorderheaderComponent,
          ediorderheaderFormComponent,
        AppConfigComponent,
        UserAdminComponent,
        LoginComponent,
        UserAdminActComponent,
        UserAdminResetPassComponent,
        UserAdminReqResetPassComponent,
        MyProfileComponent,
OrderEntryFormComponent,
portalitemmasterParrentComponent,
productlistComponent,


          portalinventoryMenuButtonComponent,
          accountconfigMenuButtonComponent,
          portalitemmasterMenuButtonComponent,





    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy}, { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true }, EventService, NodeService, BreadcrumbService, AuthGuard,MessageService, AppConfigService,UserAdminService,UserAdminService
    ],
    bootstrap:[AppComponent]
})
export class AppModule {

    constructor(private _AppConfigService: AppConfigService, private messageService: MessageService){
        this.setData();
    }

    setData() {
        //this._AppConfigService.refreshisloggedobs();
    }

 }