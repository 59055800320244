import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAdminService } from '../useradmin/useradmin.service';
import {Message} from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'useradminactivate',
  providers: [UserAdminService],
  templateUrl: './useradminactivate.component.html'
})
export class UserAdminActComponent implements OnInit, OnDestroy {
  public formData: any;
  public formmode: String;
  public routeparams: any;
  public useradminactivatedata: any;
  public loading : boolean = false;
  public msgs: Message[] = [];

  constructor(private _UserAdminService : UserAdminService, private route: ActivatedRoute, private router: Router) {
    this.formData = [];
    this.formmode = 'Activate';
  }

  ngOnInit() {
    this.routeparams = this.route.params.subscribe(params => {
       this.formData.key = params['key']; // (+) converts string 'id' to a number

       // In a real app: dispatch action to load the details here.
    });
  }

  ngOnDestroy() {
    this.routeparams.unsubscribe();
  }

  gotologin() {
    this.router.navigate(['/login']);
  }

  activate(){
    setTimeout(() => {
      this.loading = true;
    }, 100);
    this._UserAdminService.activateuseradminData(this.formData).subscribe(data => {
      this.useradminactivatedata = data;

      if(this.useradminactivatedata.error == true){
        this.msgs.push({severity: 'error', summary: 'User Admin - Activate Account Message', detail: this.useradminactivatedata.message});
      }
      else{
        this.msgs.push({severity: 'success', summary: 'User Admin - Activate Account Message', detail: this.useradminactivatedata.message});
        this.formmode = 'Activated';
      }

      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

}
