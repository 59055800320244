import {Injectable} from '@angular/core';
    import { HttpClient, HttpParams } from '@angular/common/http';
    import 'rxjs/add/operator/map';
    import {Observable} from 'rxjs/Rx';

    @Injectable()
    export class OrderEntryService {
      public result: any;

      constructor(private http: HttpClient){
          //console.log('OrderEntryService Initialized...');
      }
      getOrderEntryData(filterData){
        let data = new HttpParams()
        
    .set('lazy_first', filterData.lazy_first)
    .set('lazy_rows', filterData.lazy_rows)
    .set('lazy_sortField', filterData.lazy_sortField)
    .set('lazy_sortOrder', filterData.lazy_sortOrder)
        
        .set('eoh_clientref', filterData.eoh_clientref)
        .set('eoh_shiptoref', filterData.eoh_shiptoref)
        .set('eoh_status', filterData.eoh_status)
        .set('eoh_shiptoname', filterData.eoh_shiptoname)
        .set('eoh_orderdate_startstring', filterData.eoh_orderdate_startstring)
        .set('eoh_orderdate_endstring', filterData.eoh_orderdate_endstring);

        return this.http.post('/OrderEntry_getall', data, { withCredentials: true })
        .catch(err =>  {
          console.log(err);
          return Observable.throw(err); // observable needs to be returned or exception raised
        });
      }

      getOrderEntryNumData(filterData){
        let data = new HttpParams()

        .set('eoh_clientref', filterData.eoh_clientref)
        .set('eoh_shiptoref', filterData.eoh_shiptoref)
        .set('eoh_status', filterData.eoh_status)
        .set('eoh_shiptoname', filterData.eoh_shiptoname)
        .set('eoh_orderdate_startstring', filterData.eoh_orderdate_startstring)
        .set('eoh_orderdate_endstring', filterData.eoh_orderdate_endstring);

        return this.http.post('/OrderEntry_getnum', data, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err); // observable needs to be returned or exception raised
        });
      }

      getOrderEntrySingleData(id){
        let data = new HttpParams();

        return this.http.get('/OrderEntry_getsingle/' + id, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err); // observable needs to be returned or exception raised
        });
      }

      addOrderEntryData(formData){
        let data = new HttpParams()
        .set('eoh_clientref', formData.eoh_clientref)
        .set('eoh_shiptoref', formData.eoh_shiptoref)
        .set('eoh_status', formData.eoh_status)
        .set('eoh_shiptoname', formData.eoh_shiptoname)
        .set('eoh_shiptoaddress1', formData.eoh_shiptoaddress1)
        .set('eoh_shiptoaddress2', formData.eoh_shiptoaddress2)
        .set('eoh_shiptocity', formData.eoh_shiptocity)
        .set('eoh_shiptostate', formData.eoh_shiptostate)
        .set('eoh_shiptozipcode', formData.eoh_shiptozipcode)
        .set('eoh_shiptocountry', formData.eoh_shiptocountry)
        .set('eoh_sendtobeeline', formData.eoh_sendtobeeline);
        return this.http.post<any>('/OrderEntry_add', data, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err);
        });
      }

    
      updateOrderEntryData(formData){
        let data = new HttpParams()
            .set('eoh_clientref', formData.eoh_clientref)
            .set('eoh_shiptoref', formData.eoh_shiptoref)
            .set('eoh_status', formData.eoh_status)
            .set('eoh_shiptoname', formData.eoh_shiptoname)
            .set('eoh_shiptoaddress1', formData.eoh_shiptoaddress1)
            .set('eoh_shiptoaddress2', formData.eoh_shiptoaddress2)
            .set('eoh_shiptocity', formData.eoh_shiptocity)
            .set('eoh_shiptostate', formData.eoh_shiptostate)
            .set('eoh_shiptozipcode', formData.eoh_shiptozipcode)
            .set('eoh_shiptocountry', formData.eoh_shiptocountry)
            .set('eoh_sendtobeeline', formData.eoh_sendtobeeline);
        return this.http.put('/OrderEntry_update/' + formData.id, data, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err);
        });
      }
    
      getportalitemmasterParrentData(extraquerystring){
        let data = new HttpParams()
        .set('extraquerystring', extraquerystring);
  
        return this.http.post('/OrderEntryportalitemmaster_getparrent', data, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err);
        });
      }

      OrderEntryportalitemmaster_getitemlist(){
        let data = new HttpParams();
  
        return this.http.post('/OrderEntryportalitemmaster_getitemlist', data, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err);
        });
      }



      OrderEntryportalitemmaster_getdata(parrentid){
        let data = new HttpParams()
        .set('parrentid', parrentid);
  
        return this.http.post('/OrderEntryportalitemmaster_getdata', data, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err);
        });
      }


      OrderEntryportalitemmaster_savedata(formDataSave){
        let data = new HttpParams()
        .set('jsonstring', formDataSave.jsonstring)
        .set('parrentid', formDataSave.parrentid);
  
        return this.http.post('/OrderEntryportalitemmaster_savedata', data, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err);
        });
      }


  }