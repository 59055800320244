import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { catchError } from 'rxjs/operators';


@Injectable()
export class AppConfigService {
  private configdata:any = [];
  private configdatasubject = new BehaviorSubject<any>(this.configdata);

    constructor(private http: HttpClient){
        console.log('AppConfigService Initialized...');
        this.getappconfigDataInit();


    }

    getappconfigDataInit(){
      // let data = new URLSearchParams();
      // data.append('mode', formData.mode);
      //.map(res => res.json())

      const params = new HttpParams();

      this.http.post('/appconfig_getall', params)
      .pipe(catchError(err =>  {return Observable.throw(err);}))
      .subscribe(
        data => {
          this.configdata = data;

          this.configdatasubject.next(this.configdata);
          console.log(data);

        },
        error => {
          //console.log(error.toString());
        }
      );

    }

    getappconfigData(){

      const params = new HttpParams();


      return this.http.post('/appconfig_getall', params)
        .catch(err =>  {return Observable.throw(err);});

    }

    refreshconfigdataobs(){
      this.configdatasubject.next(this.configdata);
    }

    getappconfigdataobs(): Observable<any> {
      return this.configdatasubject.asObservable();
    }

    saveconfigData(appconfigdata){
      let data = new HttpParams()
        
        .set('conf_logo', appconfigdata.conf_logo)
        .set('conf_logolarge', appconfigdata.conf_logolarge)
        .set('conf_logowidth', appconfigdata.conf_logowidth)
        .set('conf_apptitle', appconfigdata.conf_apptitle)
        .set('conf_copyright', appconfigdata.conf_copyright)
        .set('conf_appsuffix', appconfigdata.conf_appsuffix)
        .set('conf_homepage', appconfigdata.conf_homepage)
        .set('conf_approot', appconfigdata.conf_approot)
        .set('conf_approotfrontend', appconfigdata.conf_approotfrontend);

        return this.http.put('/appconfig_update', appconfigdata)
        .pipe(catchError(err =>  {return Observable.throw(err);}));
     }
}