import {Injectable} from '@angular/core';
    import { HttpClient, HttpParams } from '@angular/common/http';
    import 'rxjs/add/operator/map';
    import {Observable} from 'rxjs/Rx';;

    @Injectable()
    export class InvDashboardService {
        public result: any;

        constructor(private http: HttpClient){
            //console.log('InvDashboardService Initialized...');
        }

        getInvDashboardData(filterData){
          let data = new HttpParams()
          .set('pinv_cukey', filterData.pinv_cukey)
          .set('pinv_prodcode', filterData.pinv_prodcode)
          .set('pinv_proddesc', filterData.pinv_proddesc)
          .set('pinv_lot', filterData.pinv_lot)
          .set('pinv_proddate', filterData.pinv_proddate)
          .set('pinv_qty', filterData.pinv_qty)
          .set('pinv_uom', filterData.pinv_uom)
          .set('pinv_bin', filterData.pinv_bin)
          .set('pinv_whse', filterData.pinv_whse);

          return this.http.post('/InvDashboard_getall', data, { withCredentials: true })
          .catch(err =>  {
            console.log(err);
            return Observable.throw(err); // observable needs to be returned or exception raised
          });

        }

        getInvDashboardSingleData(id){
          let data = new HttpParams();
            //let data = new URLSearchParams();
            //.map(res => res.results)

            return this.http.get('/InvDashboard_getsingle/' + id, { withCredentials: true })

                .catch(err =>  {
                  return Observable.throw(err); // observable needs to be returned or exception raised
                });
        }
  }