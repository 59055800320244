import { Component, OnInit } from '@angular/core';
import { UserAdminService } from './useradmin.service';
import {Message, ConfirmationService, MenuItem} from 'primeng/primeng';
import {BreadcrumbService} from '../breadcrumb.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'useradmin',
  providers: [ConfirmationService],
  templateUrl: './useradmin.component.html',
  styles: [`
  :host ::ng-deep .ui-dialog {
    width: 90% !important;
    max-width: 1100px !important;
  }

  :host ::ng-deep .ui-message-info{
    background-color: #77bae8;
  }

  :host ::ng-deep .ui-message-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-messages-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-message-success{
    background-color: #b2d7b8;
  }

  :host ::ng-deep .ui-table-auto-layout > .ui-table-wrapper{
    overflow-x:visible;
  }

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .container h2 {
    display: block;
    flex-basis: 100%;
  }

  .itemfulllength{
    flex-basis: 100%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .headeritemleft{
    flex-basis: 35%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .headeritemmiddle{
    flex-basis: 30%;
    font-size:12pt;
    text-align: center;
  }

  .headeritemright{
    flex-basis: 35%;
    font-size:14pt;
    display: flex;
    justify-content: flex-end;
  }

  .headeritemrightbutton{
    margin-left:10px;
  }

  .filtercontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .filteritem{
    flex-basis: 16%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .filterfooter{
    flex-basis: 100%;
    text-align:right;
    margin-top:7px;
    margin-bottom:7px;
  }

  @media only screen and (min-width: 10px) and (max-width: 479px){

    .filtercontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
    }

    .filteritem{
      flex-basis: 100%;
      margin-top:7px;
      margin-bottom:1px;
    }

  }

  @media only screen and (min-width: 480px) and (max-width: 767px){


    .filteritem{
      flex-basis: 32%;
      margin-top:7px;
      margin-bottom:7px;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 1200px){

    .filteritem{
      flex-basis: 25%;
      margin-top:7px;
      margin-bottom:7px;
    }

  }

  @media only screen and (min-width: 992px) and (max-width: 1999px){


  }

  `]
})
export class UserAdminComponent implements OnInit {
  public formData: any;
  public useradmindata: any;
  public homepagearr: any = [];
  public loading : boolean = false;
  public msgs: Message[] = [];
  public displayaddedit : boolean = false;
  public formactivemode: String;
  public formactiveid: String;
  public formLabel_header: String = '';
  splitbuttonitems: MenuItem[];
  public splitbuttonid: String;

  constructor(private _UserAdminService : UserAdminService, private _ConfirmationService:ConfirmationService, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {
    this.formData = [];
    this.formData.id = '1';

    
        this.homepagearr['InvDashboard'] = 'Inventory Dashboard'
        this.homepagearr['OrderEntry'] = 'Order entry'
        this.homepagearr['StockTransactionLog'] = 'Stock transaction log'
  }

  ngOnInit() {
    this.getUserssData();

    this.splitbuttonitems = [
      {label: 'Copy', icon: 'fa fa-copy', command: () => {
          this.formshow('copy', this.splitbuttonid);
      }},
      {label: 'Delete', icon: 'fa fa-trash', command: () => {
          this.delete(this.splitbuttonid);
      }}
    ];

    this.breadcrumbService.setItems([
      {label: 'blportal'},
      {label: 'User Admin', routerLink: ['/useradmin']}
    ]);

  }

  formshow(mode, id = null){

    this.formactivemode = mode;
    this.formactiveid = id;
    this.displayaddedit = true;

    if(mode == 'modify'){
      this.formLabel_header = 'Modify';
    }
    else if(mode == 'copy' || mode == 'create'){
      this.formLabel_header = 'Create';
    }

  }

  formclose(){
    this.displayaddedit = false;
  }

  handleformClosed($event){
    //console.log('hellp');
    this.formclose();
    this.getUserssData();
  }

  getUserssData(){
    setTimeout(() => {
      this.messageService.add({key: 'maintoast', severity:'info', summary:'User Admin Message', detail:'Loading Data'});
      this.loading = true;
    }, 100);
    this._UserAdminService.getuseradminData(this.formData).subscribe(data => {
      this.useradmindata = data;
      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

  delete(id){
    this._ConfirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.loading = true;
        this._UserAdminService.deleteuser(id).subscribe(data => {
          this.messageService.add({key: 'maintoast', severity:'success', summary:'User Admin Message', detail:'User deleted with success'});
          setTimeout(() => {
            this.loading = false;
          }, 500);
          console.log(this.formData);
          console.log(data);
          this.getUserssData();
        },
        error => {
          this.messageService.add({key: 'maintoast', severity:'error', summary:'User Admin Message', detail:'Error deleting user'});
          console.log(this.formData);
          console.log(error);
        });
      }
    });
  }
}
