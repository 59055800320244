import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy} from '@angular/core';
import {DatePipe} from '@angular/common';
import {accountconfigService} from './accountconfig.service';
import {SelectItem, Message, MenuItem, OverlayPanel, ConfirmationService, LazyLoadEvent} from 'primeng/primeng';
import {accountconfig} from './domain/accountconfig';
import {Router} from '@angular/router';
import {AppConfigService} from '../appconfig/appconfig.service';
import {UserAdminService} from '../useradmin/useradmin.service';
import {Subscription} from 'rxjs/Subscription';
import {BreadcrumbService} from '../breadcrumb.service';
import {MessageService} from 'primeng/api';
import {interval} from 'rxjs/observable/interval';
import {timer} from 'rxjs/observable/timer';

@Component({
  selector: 'datalist-accountconfig',
  providers: [accountconfigService, DatePipe, ConfirmationService],
  templateUrl: './datalist-accountconfig.component.html',
  styles: [`

  :host ::ng-deep .ui-dialog {
    width: 98% !important;
    max-width: 1100px !important;
  }

  :host ::ng-deep .ui-message-info{
    background-color: #77bae8;
  }

  :host ::ng-deep .ui-message-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-messages-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-message-success{
    background-color: #b2d7b8;
  }

  :host ::ng-deep .ui-table-auto-layout > .ui-table-wrapper{
    overflow-x:visible;
  }

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .container h2 {
    display: block;
    flex-basis: 100%;
  }

  .itemfulllength{
    flex-basis: 100%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .headeritemleft{
    flex-basis: 35%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .headeritemmiddle{
    flex-basis: 30%;
    font-size:12pt;
    text-align: center;
  }

  .headeritemright{
    flex-basis: 35%;
    font-size:14pt;
    display: flex;
    justify-content: flex-end;
  }

  .headeritemrightbutton{
    margin-left:10px;
  }

  .filtercontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .filteritem{
    flex-basis: 16%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .filterfooter{
    flex-basis: 100%;
    text-align:right;
    margin-top:7px;
    margin-bottom:7px;
  }

  @media only screen and (min-width: 10px) and (max-width: 479px){

    .filtercontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
    }

    .filteritem{
      flex-basis: 100%;
      margin-top:7px;
      margin-bottom:1px;
    }

    .headeritemmiddle{
      flex-basis: 0%;
      display:none;
    }

    .headeritemleft{
      flex-basis: 50%;
    }
    
    .headeritemright{
      flex-basis: 50%;
    }


  }

  @media only screen and (min-width: 480px) and (max-width: 767px){


    .filteritem{
      flex-basis: 32%;
      margin-top:7px;
      margin-bottom:7px;
    }

    .headeritemmiddle{
      flex-basis: 0%;
      display:none;
    }

    .headeritemleft{
      flex-basis: 50%;
    }
    
    .headeritemright{
      flex-basis: 50%;
    }


  }

  @media only screen and (min-width: 768px) and (max-width: 1200px){

    .filteritem{
      flex-basis: 25%;
      margin-top:7px;
      margin-bottom:7px;
    }

  }

  @media only screen and (min-width: 992px) and (max-width: 1999px){

  

  }


  `]
})
export class accountconfigComponent implements OnInit{
  public accountconfigdata : any = [];
  @Input() defaultcreatevalues:any = [];
  @Input() showfilter : boolean = true;
  @Output() accountconfigAdded = new EventEmitter();
  public loading : boolean = false;
  public displayaddedit : boolean = false;
  public msgs: Message[] = [];
  public formData: any = [];
  public formactivemode: String;
  public formactiveid: String;
  public splitbuttonid: String;
  public userinfo: any;
  public appconfig: any;
  first : number;

  @ViewChild('accountconfigform', {static: false}) accountconfigFormComponent;
  @ViewChild('dt', {static: false}) myTable: any;


  public filterData: any = [];
  public filterDataShow: any = [];
  public totalRecords: any;
  splitbuttonitems: MenuItem[];
  
  
  
  

  subscriptionislogged: Subscription;
  subscriptionappconfig: Subscription;

  /*
  selectedaccountconfig : accountconfig= {
    aconf_id: 1,
    
  aconf_cukey: '',
  aconf_username: '',
  aconf_companyname: '',
  };
  */

      

  //selectedaccountconfig.aconf_id = 1;

  constructor(private _accountconfigService:accountconfigService, public datepipe: DatePipe, private _ConfirmationService:ConfirmationService, private router: Router, private _UserAdminService : UserAdminService, private _AppConfigService: AppConfigService, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {
    //this.selectedaccountconfig.aconf_id = '';
    this.userinfo = [];
    this.appconfig = [];
    this.splitbuttonitems = [
      {label: 'Copy', icon: 'fa fa-copy', command: () => {
          this.formshow('copy', this.splitbuttonid);
      }},
      {label: 'Delete', icon: 'fa fa-trash', command: () => {
        this.delete(this.splitbuttonid);
      }}
      
    ];
    this.breadcrumbService.setItems([
      {label: 'BeeLine Portal'},
      {label: 'Account config', routerLink: ['/accountconfig']}
    ]);    
  }

  
  //{label: 'Angular.io', icon: 'fa-link', url: 'http://angular.io'},
  //{label: 'Theming', icon: 'fa-paint-brush', routerLink: ['/theming']}

  ngOnInit() {
    //this.formData = [];
    this.filterData.lazy_first = 0;
    this.filterData.lazy_rows = 10;
      this.filterData.lazy_sortField = 'aconf_id';
      this.filterData.lazy_sortOrder = -1;
      this.filterData.aconf_cukey = '';
      this.filterData.aconf_username = '';
      this.filterData.aconf_companyname = '';

        

    this.totalRecords = 0;


    //this.getaccountconfigData();

    this.subscriptionislogged = this._UserAdminService.isloggedobs().subscribe(
      userinfo => {
        this.userinfo = userinfo;

        //console.log(userinfo);
      }
    );

    //this._UserAdminService.refreshisloggedobs();


    this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
      appconfig => {
      this.appconfig = appconfig[0];
      //console.log(this.appconfig);
      }
    );

    //this._AppConfigService.refreshconfigdataobs();

  }

  secondbeforerefresh = 0;
  subscribe;
  source;

  filterrefresh(second = 3){

    //this.dataTable.filter(event.target.value, field, 'contains');

    

    //alert('filter refresh');
    //emit value in sequence every 1 second
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    this.secondbeforerefresh = second;

    this.source = interval(1000);
    //output: 0,1,2,3,4,5....
    this.subscribe = this.source.subscribe(val => {
      if(this.secondbeforerefresh > 0){
        this.secondbeforerefresh = this.secondbeforerefresh - 1;
      }
      if(this.secondbeforerefresh == 0){
        this.subscribe.unsubscribe();
        this.filterData.lazy_first = 0;
        console.log(this.filterData);

        this._accountconfigService.getaccountconfigNumData(this.filterData).subscribe(data => {
          this.totalRecords = data[0]['countrow'];
          this.getaccountconfigData();
          this.myTable.first = 0;
          //console.log(data);
        },
        error => {
          console.log(error); // gives the object object
        });

      }
    });
  }

  resetfilter(){
    
      this.filterData.aconf_cukey = '';
      this.filterData.aconf_username = '';
      this.filterData.aconf_companyname = '';
    this.getaccountconfigData();
  }

  formshow(mode, id = null){
    this.formactivemode = mode;
    this.formactiveid = id;
    this.displayaddedit = true;

    this.accountconfigFormComponent.formshow(mode, id);
  }

  formclose(){
    this.displayaddedit = false;
  }

  handleformClosed(id){
    this.formclose();
    this.getaccountconfigData();
  }


  delete(id){
    this._ConfirmationService.confirm({
        message: 'Do you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'fa fa-trash',
        accept: () => {
          this.loading = true;
          this._accountconfigService.deleteaccountconfigData(id).subscribe(data => {
            this.messageService.add({key: 'maintoast', severity:'success', summary:'Account config Message', detail:'Record deleted with success'});
            setTimeout(() => {
              this.loading = false;
            }, 500);
            //console.log(this.formData);
            //console.log(data);
            this.getaccountconfigData();
          },
          error => {
            this.messageService.add({key: 'maintoast', severity:'error', summary:'Account config Message', detail:'Error deleting record'});
            //console.log(this.formData);
            //console.log(error);
          });
        }
    });
  }

  getaccountconfigData() {
    //console.log(this.filterData);
    setTimeout(() => {
      this.messageService.add({key: 'maintoast', severity:'info', summary:'Account config Message', detail:'Loading data'});
      this.loading = true;
    }, 100);
    this._accountconfigService.getaccountconfigData(this.filterData).subscribe(data => {
      if(data){
        this.accountconfigdata = data;
      }
      else{
        this.accountconfigdata = [];
      }
      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

  loadaccountconfigLazy(event: LazyLoadEvent) {
    console.log(event);
    this.filterData.lazy_first = event.first;
    this.filterData.lazy_rows = event.rows;
    this.filterData.lazy_sortField = event.sortField;
    this.filterData.lazy_sortOrder = event.sortOrder;

    this._accountconfigService.getaccountconfigNumData(this.filterData).subscribe(data => {
      this.totalRecords = data[0]['countrow'];
      this.getaccountconfigData();

      //console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });

  }
}