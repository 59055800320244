import {Injectable} from '@angular/core';
//import {Http, Headers, URLSearchParams} from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class portalitemmasterService {
  public result: any;

  constructor(private http: HttpClient){
      //console.log('BeeLine portal item master Service Initialized...');
  }

  getportalitemmasterData(filterData){
    let data = new HttpParams()
    .set('lazy_first', filterData.lazy_first)
    .set('lazy_rows', filterData.lazy_rows)
    .set('lazy_sortField', filterData.lazy_sortField)
    .set('lazy_sortOrder', filterData.lazy_sortOrder)
    
        .set('pitm_cukey', filterData.pitm_cukey)
        .set('pitm_system', filterData.pitm_system)
        .set('pitm_prodcode', filterData.pitm_prodcode)
        .set('pitm_proddesc', filterData.pitm_proddesc)
        .set('pitm_uom', filterData.pitm_uom);

    return this.http.post('/portalitemmaster_getall', data, { withCredentials: true })
    .catch(err =>  {
      console.log(err);
      return Observable.throw(err);
    });
  }

  getportalitemmasterNumData(filterData){
    let data = new HttpParams()

        .set('pitm_cukey', filterData.pitm_cukey)
        .set('pitm_system', filterData.pitm_system)
        .set('pitm_prodcode', filterData.pitm_prodcode)
        .set('pitm_proddesc', filterData.pitm_proddesc)
        .set('pitm_uom', filterData.pitm_uom);
    return this.http.post('/portalitemmaster_getnum', data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  getportalitemmasterSingleData(id){
    let data = new HttpParams();
    return this.http.get('/portalitemmaster_getsingle/' + id, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }
  addportalitemmasterData(formData){
    let data = new HttpParams()
    
        .set('pitm_cukey', formData.pitm_cukey)
        .set('pitm_system', formData.pitm_system)
        .set('pitm_prodcode', formData.pitm_prodcode)
        .set('pitm_proddesc', formData.pitm_proddesc)
        .set('pitm_uom', formData.pitm_uom);
    return this.http.post<any>('/portalitemmaster_add', data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  updateportalitemmasterData(formData){
    let data = new HttpParams()

        .set('pitm_cukey', formData.pitm_cukey)
        .set('pitm_system', formData.pitm_system)
        .set('pitm_prodcode', formData.pitm_prodcode)
        .set('pitm_proddesc', formData.pitm_proddesc)
        .set('pitm_uom', formData.pitm_uom);
    return this.http.put('/portalitemmaster_update/' + formData.id, data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  deleteportalitemmasterData(id){
    return this.http.delete('/portalitemmaster_delete/' + id, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }
  getportalitemmasterParrentData(extraquerystring){
      let data = new HttpParams()
      .set('extraquerystring', extraquerystring);

      return this.http.post('/portalitemmaster_getparrent', data, { withCredentials: true })
      .catch(err =>  {
        return Observable.throw(err);
      });
  }
}
