
import { Injectable } from '@angular/core';
//import {Http, Headers, URLSearchParams} from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs/Rx';
import { UserAdminService } from '../useradmin/useradmin.service';
 
@Injectable()
export class AuthGuard implements CanActivate {
    public data : any;
    public authresult : boolean;
    public accesstype : string;
    public accesslevel : string;
    public userinforesult : any;

    constructor(private router: Router, private http: HttpClient, private _UserAdminService:UserAdminService) {
      this.data = [];
      this.data.islogged = false;
    }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean>{

      return this._UserAdminService.checkaccess(route.data['secneeded'], route.data['seclevelneeded']).map(data => {

      this.userinforesult = data;
      this.accesstype = route.data['secneeded'];
      this.accesslevel = route.data['seclevelneeded'];

      //console.log(this.userinforesult);

      if(this.userinforesult.islogged != false){
        if(this.accesstype == 'islogged'){
          return true;
        }
        else if(this.userinforesult.mysecurity['sec_'+this.accesstype][this.accesslevel] != false){
          return true;
        }
        else{
          alert('ACCESS DENIED');
          return false;
        }
      }
      else{
        this.router.navigate(['/login']);
        return false;
      }

    },
    error => {
      //console.log(error); // gives the object object
    });
/*
    this.authresult = this._UserAdminService.checkaccess(route.data['secneeded'], route.data['seclevelneeded']);

    //console.log(this.authresult);

    if(this.authresult == false){
      this.router.navigate(['/login']);
    }
    else{

    }

    return this.authresult ; */
   }
}