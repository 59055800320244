import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy} from '@angular/core';
import { TransactionLogService } from './StockTransactionLog.service';
import {SelectItem, Message, MenuItem, OverlayPanel, ConfirmationService, LazyLoadEvent} from 'primeng/primeng';
import {DatePipe} from '@angular/common';
import { AppConfigService } from '../appconfig/appconfig.service';
import { UserAdminService } from '../useradmin/useradmin.service';
import { Subscription } from 'rxjs/Subscription';
import { interval } from 'rxjs/observable/interval';
import { timer } from 'rxjs/observable/timer';
import {BreadcrumbService} from '../breadcrumb.service';
import {MessageService} from 'primeng/api';



@Component({
  selector: 'StockTransactionLog',
  providers: [TransactionLogService, DatePipe, ConfirmationService],
  templateUrl: './StockTransactionLog.component.html',
  styles: [`

  :host ::ng-deep .ui-dialog {
    width: 98% !important;
    max-width: 1100px !important;
  }

  :host ::ng-deep .ui-message-info{
    background-color: #77bae8;
  }

  :host ::ng-deep .ui-message-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-messages-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-message-success{
    background-color: #b2d7b8;
  }

  :host ::ng-deep .ui-table-auto-layout > .ui-table-wrapper{
    overflow-x:visible;
  }

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .container h2 {
    display: block;
    flex-basis: 100%;
  }

  .itemfulllength{
    flex-basis: 100%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .headeritemleft{
    flex-basis: 35%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .headeritemmiddle{
    flex-basis: 30%;
    font-size:12pt;
    text-align: center;
  }

  .headeritemright{
    flex-basis: 35%;
    font-size:14pt;
    display: flex;
    justify-content: flex-end;
  }

  .headeritemrightbutton{
    margin-left:10px;
  }

  .filtercontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .filteritem{
    flex-basis: 16%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .filterfooter{
    flex-basis: 100%;
    text-align:right;
    margin-top:7px;
    margin-bottom:7px;
  }

  @media only screen and (min-width: 10px) and (max-width: 479px){

    .filtercontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
    }

    .filteritem{
      flex-basis: 100%;
      margin-top:7px;
      margin-bottom:1px;
    }

    .headeritemmiddle{
      flex-basis: 0%;
      display:none;
    }

    .headeritemleft{
      flex-basis: 50%;
    }
    
    .headeritemright{
      flex-basis: 50%;
    }

  }

  @media only screen and (min-width: 480px) and (max-width: 767px){


    .filteritem{
      flex-basis: 32%;
      margin-top:7px;
      margin-bottom:7px;
    }

    .headeritemmiddle{
      flex-basis: 0%;
      display:none;
    }

    .headeritemleft{
      flex-basis: 50%;
    }
    
    .headeritemright{
      flex-basis: 50%;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 1200px){

    .filteritem{
      flex-basis: 25%;
      margin-top:7px;
      margin-bottom:7px;
    }

  }

  @media only screen and (min-width: 992px) and (max-width: 1999px){


  }


  `]
})
export class TransactionLogComponent implements OnInit {
  public StockTransactionLogdata : any = [];
  @Input() defaultcreatevalues:any = [];
  @Output() transactionlogAdded = new EventEmitter();
   public loading : boolean = false;
  public msgs: Message[] = [];
  public totalRecords: any;



  

  public userinfo: any = [];
  public appconfig: any = [];

  subscriptionuserinfo: Subscription;
  subscriptionappconfig: Subscription;

  public filterData: any = [];
  public filterDataShow : any = [];
  
    @Input() filtertransdate : String;
    @Input() filtertranstype : String;
    @Input() filtertransqty : String;
    @Input() filtertransuom : String;
    @Input() filterprodcode : String;
    @Input() filterproddesc : String;
    @Input() filterblref : String;
    @Input() filtercustref : String;
  
  
  

  @ViewChild('dt', {static: false}) myTable: any;

  constructor(private _TransactionLogService : TransactionLogService, public datepipe: DatePipe, private _UserAdminService : UserAdminService, private _AppConfigService: AppConfigService, private _ConfirmationService:ConfirmationService, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {

    this.breadcrumbService.setItems([
      {label: 'blportal'},
      {label: 'Stock transaction log', routerLink: ['/StockTransactionLog']}
    ]);

    
  }
  
  ngOnInit() {
    
    this.gettransactionlogData();
    
      this.filterData.tlg_transdate = '';
      this.filterData.tlg_transtype = '';
      this.filterData.tlg_transqty = '';
      this.filterData.tlg_transuom = '';
      this.filterData.tlg_prodcode = '';
      this.filterData.tlg_proddesc = '';
      this.filterData.tlg_blref = '';
      this.filterData.tlg_custref = '';
    

    this.subscriptionuserinfo = this._UserAdminService.isloggedobs().subscribe(
      userinfo => {
        this.userinfo = userinfo;

        //console.log(userinfo);
      }
    );

    this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
      appconfig => {
        this.appconfig = appconfig[0];
        //console.log(this.appconfig);
      }
    );

  }

  
  

  gettransactionlogData() {
    //console.log(this.filterData);
    setTimeout(() => {
      this.msgs = [];
      this.msgs.push({severity: 'info', summary: 'Stock transaction log Message', detail: 'Loading Data'});
      this.loading = true;
    }, 100);
    this._TransactionLogService.getStockTransactionLogData(this.filterData).subscribe(data => {
      if(data){
        this.StockTransactionLogdata = data;
        this.messageService.add({key: 'maintoast', severity:'success', summary:'Stock transaction log Message', detail:'Data loaded with success'});
      }
      else{
        this.StockTransactionLogdata = [];
        this.messageService.add({key: 'maintoast', severity:'error', summary:'Stock transaction log Message', detail:'No record found'});
      }
      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

  secondbeforerefresh = 0;
  subscribe;
  source;

  filterrefresh(second = 3){

    //this.dataTable.filter(event.target.value, field, 'contains');

    

    //alert('filter refresh');
    //emit value in sequence every 1 second
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    this.secondbeforerefresh = second;

    this.source = interval(1000);
    //output: 0,1,2,3,4,5....
    this.subscribe = this.source.subscribe(val => {
      if(this.secondbeforerefresh > 0){
        this.secondbeforerefresh = this.secondbeforerefresh - 1;
      }
      if(this.secondbeforerefresh == 0){
        this.subscribe.unsubscribe();

      this.gettransactionlogData();
      }
    });
  }

  resetfilter(){
    
      this.filterData.tlg_transdate = '';
      this.filterData.tlg_transtype = '';
      this.filterData.tlg_transqty = '';
      this.filterData.tlg_transuom = '';
      this.filterData.tlg_prodcode = '';
      this.filterData.tlg_proddesc = '';
      this.filterData.tlg_blref = '';
      this.filterData.tlg_custref = '';
  
      this.gettransactionlogData();
  }



  excelexport() {


    window.open(this.appconfig.conf_approot+"report_StockTransactionLog.php?tlg_transdate="+this.filterData.tlg_transdate+
    "&tlg_transtype="+this.filterData.tlg_transtype+
    "&tlg_transqty="+this.filterData.tlg_transqty+
    "&tlg_transuom="+this.filterData.tlg_transuom+
    "&tlg_prodcode="+this.filterData.tlg_prodcode+
    "&tlg_proddesc="+this.filterData.tlg_proddesc+
    "&tlg_blref="+this.filterData.tlg_blref+
    "&tlg_custref="+this.filterData.tlg_custref,"_blank");


  }
  
  
}
