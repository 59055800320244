import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import {OrderEntryService} from './OrderEntry.service';
import {SelectItem} from 'primeng/primeng';
import {Message} from 'primeng/primeng';
import {FileUpload} from 'primeng/primeng';
import {Messages} from 'primeng/primeng';
import {MessageService} from 'primeng/components/common/messageservice';
import {MenuItem} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { UserAdminService } from '../useradmin/useradmin.service';
import { AppConfigService } from '../appconfig/appconfig.service';
import { Subscription } from 'rxjs/Subscription';



@Component({
  selector: 'productlist',
  providers: [OrderEntryService, MessageService, ConfirmationService ],

  template:`
  <div style="padding: 10px;">
    <p-table [value]="orderlistdata" dataKey="eod_itemnumber" editMode="row" [responsive]="true" [loading]="loading">
      <ng-template pTemplate="header">
        <tr>
            <th style="width:65%">Product - Description</th>
            <th style="width:12%">Quantity</th>
            <th style="width:10%">UOM</th>
            <th style="width:13%"></th>
        </tr>
        <tr>
          <th style="background-color:#ffffc4"> Add product to order:  <parrentportalitemmaster (portalitemmasterSelected)="handleportalitemmasterSelected($event)" [selectedportalitemmaster]="formData.portalitemmaster"   #parrentitemmaster></parrentportalitemmaster></th>
          <th style="background-color:#ffffc4"><input type="number" [(ngModel)]="formData.itemqty" pInputText  style="width:80px;"/></th>
          <th style="background-color:#ffffc4">{{formData.pitm_uom}}</th>
          <th style="width:8em; background-color:#ffffc4"><button pButton type="button" style="width: 60px;" label="Add" (click)="additem()"></button></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-detailData let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="detailData">
          <td style="text-align:center;">
            {{detailData.pitm_prodcode}} {{detailData.pitm_proddesc}}
          </td>
          <td style="text-align:center;">
              <p-cellEditor>
                  <ng-template pTemplate="input">
                      <input pInputText type="number" [(ngModel)]="detailData.itemqty" style="width:80px;">
                  </ng-template>
                  <ng-template pTemplate="output">
                      {{detailData.itemqty}}
                  </ng-template>
              </p-cellEditor>
          </td>
          <td style="text-align:center;">
            {{detailData.pitm_uom}}
          </td>
          <td style="text-align:center">
              <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="ui-button-info" style="margin-right: .5em"></button>
              <button *ngIf="!editing" pButton type="button" icon="pi pi-trash" class="ui-button-info" (click)="deleteline(ri)"></button>
              <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check" class="ui-button-success" style="margin-right: .5em"></button>
              <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times" class="ui-button-danger"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  `,
  styles: [`

  `]
})
export class productlistComponent implements OnInit {
  //@Output() proddataSelected = new EventEmitter();
  @Input() formmode = ""; //create, copy or modify
  @Input() parrentid = ""; //Id of the parrent section


  public loading : boolean = false;
  public error : boolean;
  public itemadderror : boolean;
  public uploadbutton : boolean = false;
  public viewmode : string = "listonly";

  productlistdata: any = [];
  orderlistdata: any = [];
  itemtoadd: any = [];
  formData: any = [];
  formDataSave: any = [];

  @ViewChild('fup', {static: false}) fup: FileUpload;
  @ViewChild('parrentitemmaster', {static: false}) portalitemmasterParrentComponent;

  msgs: Message[] = [];

  public userinfo: any = [];
  public appconfig: any = [];
  public secneeded_currentaccess: any;

  uploadedFiles: any[] = [];

  subscriptionislogged: Subscription;
  subscriptionappconfig: Subscription;    

  public savetemplatearr: SelectItem[];

  constructor(private _OrderEntryService:OrderEntryService, private confirmationService: ConfirmationService, private _UserAdminService : UserAdminService, private _AppConfigService : AppConfigService, private messageService: MessageService ) {
    
    this.secneeded_currentaccess = [];

   // this.userinfo = [];
   // this.userinfo.mysecurity = [];
    this.formData.itemqty = 1;

  }

  ngOnInit() {

    this.msgs = [];

    this.subscriptionislogged = this._UserAdminService.isloggedobs().subscribe(
      userinfo => {
        this.userinfo = userinfo;

        //console.log(userinfo);
      }
    );

    this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
      appconfig => {
//          alert('config');
        this.appconfig = appconfig[0];
        console.log(this.appconfig);
      }
    );
    //console.log(this.secneeded);
    //console.log(this.userinfo['mysecurity'][this.secneeded]);

    //this.getFileAttachmentData();
     this._OrderEntryService.OrderEntryportalitemmaster_getitemlist().subscribe(data => {
      this.productlistdata = data;
      console.log(this.productlistdata);
    }); 


  }

  handleportalitemmasterSelected(itemid) {
    console.log(itemid);
    this.formData.pitm_id = itemid;

    this.formData.pitm_prodcode = this.productlistdata[itemid].pitm_prodcode;
    this.formData.pitm_proddesc = this.productlistdata[itemid].pitm_proddesc;
    this.formData.pitm_uom = this.productlistdata[itemid].pitm_uom;
    console.log(this.formData);

  }

  additem() {
    
    this.itemadderror = false
    
    if(!this.formData.pitm_prodcode){
      this.itemadderror = true;
      alert('You must first select an item');
    }

    if(this.formData.pitm_prodcode == ''){
      this.itemadderror = true;
      alert('You must first select an item');
    }

    if(this.formData.itemqty <= 0){
      this.itemadderror = true;
      alert('Qty must be higher then 0');
    }
    
    this.orderlistdata.forEach(function (item) {
      console.log(item);
      if(item.pitm_prodcode == this.formData.pitm_prodcode) {
        this.itemadderror = true;
        alert('Item is already on the order, please modify the item instead');
      }
    }.bind(this));

    if(this.itemadderror == false){
      this.orderlistdata.push({pitm_prodcode: this.formData.pitm_prodcode, pitm_proddesc: this.formData.pitm_proddesc, itemqty : this.formData.itemqty, pitm_uom: this.formData.pitm_uom});
      this.formData.itemqty = 1;
      this.portalitemmasterParrentComponent.selectedportalitemmaster = '';
      this.portalitemmasterParrentComponent.selectedportalitemmaster_label = '';
      console.log(this.orderlistdata);
    }

    

  }

  save(){
    this.formDataSave.jsonstring = JSON.stringify(this.orderlistdata);
    this.formDataSave.parrentid = this.parrentid;
    console.log(this.formDataSave);

    this._OrderEntryService.OrderEntryportalitemmaster_savedata(this.formDataSave).subscribe((data:any) => {

      setTimeout(() => {

        this.loading = false;

        if(data.success){
//          this.messageService.add({key: 'maintoast', severity:'success', summary:'EDI Order Header Message', detail:'Record created with success'});
        

          
//          this.formshow('modify',data.id);
        }
        else{
//          this.messageService.add({key: 'maintoast', severity:'error', summary:'EDI Order Header Message', detail:'Error creating record'});
        }
      }, 2000);
    });

  }


  getdata(){

    this.loading = true;

    this._OrderEntryService.OrderEntryportalitemmaster_getdata(this.parrentid).subscribe((data:any) => {

      this.orderlistdata = data;

      setTimeout(() => {

        this.loading = false;


      }, 500);
    });

  }


  deleteline(ri){

    this.orderlistdata.splice(ri, 1);

  }

  cleardata(){
    this.orderlistdata = [];
    this.formData.itemqty = 1;
    this.portalitemmasterParrentComponent.selectedportalitemmaster = '';
    this.portalitemmasterParrentComponent.selectedportalitemmaster_label = '';
  }

}