import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import {OrderEntryService} from './OrderEntry.service';
import {SelectItem} from 'primeng/primeng';

@Component({
  selector: 'parrentportalitemmaster',
  providers: [OrderEntryService],

  template:`<p-dropdown [options]="resultportalitemmaster" [(ngModel)]="selectedportalitemmaster"  [autoWidth]="false"  filter="filter" (onChange)="changeval()" [disabled]="loading" [readonly]="readonlyfield" [styleClass]="readonlyfield ? 'readonlyprimeng' : ''"></p-dropdown>`,
  styles: [`
  :host ::ng-deep .ui-dropdown{
    width: 25vw;
    max-width: 337px;
  }

  @media only screen and (min-width: 0px) and (max-width: 767px){

    :host ::ng-deep .ui-dropdown{
      width: 100%;
      max-width: 440px;
    }
  
  }  
  `]
})
export class portalitemmasterParrentComponent implements OnInit, OnChanges {
  @Output() portalitemmasterSelected = new EventEmitter();
  @Output() portalitemmasterSelected_label = new EventEmitter();
  @Input() selectedportalitemmaster = "";
  @Input() selectedportalitemmaster_label = "";
  @Input() extraquerystring = "";
  @Input() autoselectfirst : boolean = false;
  @Input() readonlyfield : boolean = false;
  public resultportalitemmaster: any = [];
  public loading : boolean = false;
  public error : boolean;

// changeDetection: ChangeDetectionStrategy.OnPush,

  constructor(private _OrderEntryService:OrderEntryService) {


  }

  ngOnInit() {
    //this.selectedportalitemmaster = '';
    this.getportalitemmasterData();
  }

  ngOnChanges(...args: any[]) {
    //console.log('onChange fired');
    //console.log('OnChange', args);
    if(args[0]['extraquerystring'] != null){
      //console.log('refresh parrent');
      this.getportalitemmasterData();
    }
  }

  

  selectfirst(){
    console.log(this.resultportalitemmaster);
    if(this.resultportalitemmaster[1]){
      this.selectedportalitemmaster = this.resultportalitemmaster[1]['value']
      this.selectedportalitemmaster_label = this.resultportalitemmaster[1]['label']
      //console.log(this.selectedportalitemmaster);
      this.portalitemmasterSelected.emit(this.selectedportalitemmaster);
      //console.log('result1 exist portalitemmaster');
    }
    else{
      this.selectedportalitemmaster = '';
      this.selectedportalitemmaster_label = '';
      //console.log('result1 does not exist portalitemmaster');
    }
    //console.log(this.resultportalitemmaster);
    this.changeval();
  }

  labelByValue(value){
    let i =  this.resultportalitemmaster.find(item => item.value == value);
    return i ? i.label : "";
  }

  changeval(){
    this.selectedportalitemmaster_label = this.labelByValue(this.selectedportalitemmaster);
    console.log(this.selectedportalitemmaster_label);
    this.portalitemmasterSelected.emit(this.selectedportalitemmaster);
    this.portalitemmasterSelected_label.emit(this.selectedportalitemmaster_label);
  }

  getportalitemmasterData(id = null) {
    setTimeout(() => {
      this.loading = true;
    }, 1000);
    this._OrderEntryService.getportalitemmasterParrentData(this.extraquerystring).subscribe(data => {
      this.resultportalitemmaster = data;
      setTimeout(() => {
        this.loading = false;
        if(id){
          this.selectedportalitemmaster = id;
          this.portalitemmasterSelected.emit(this.selectedportalitemmaster);
        }
        else if(this.autoselectfirst){
          this.selectfirst();
        }
      }, 1000);
      console.log(data);

    },
    error => {
      console.log(error); // gives the object object
    });
  }



}