import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import {UserAdminService} from './useradmin.service';
import {SelectItem, Message, MenuItem, OverlayPanel, LazyLoadEvent} from 'primeng/primeng';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'form-useradmin',
  providers: [DatePipe],
  templateUrl: './form-useradmin.component.html',
  styles: [`

  :host ::ng-deep .ui-message-info{
    background-color: #77bae8;
  }

  :host ::ng-deep .ui-message-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-messages-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-message-success{
    background-color: #b2d7b8;
  }

  :host >>> .ui-dialog .ui-dialog-titlebar{
    background-color: #2b3135;
    color:#FFFFFF;
  }

  :host >>> .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon .fa{
    color:#FFFFFF;
  }

  :host >>> body .ui-dialog .ui-dialog-content {
    padding: 0.625em 0em;
    padding-left: 1em;
  }

  :host >>> body .ui-inputtext .ng-dirty {
    border-color: #a6a6a6;
  }

  .formcontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .formcontainer h2 {
    display: block;
    flex-basis: 100%;
  }

  .formitemlabel{
    flex-basis: 16%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .formiteminput{
    flex-basis: 34%;
    margin-top:7px;
    margin-bottom:7px;
    padding-right:20px;
  }

  .formitemfulllength{
    flex-basis: 100%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .viewstackcontainer{
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .viewstackright{
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .viewstackrightlabel{
    flex-basis: 32%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .viewstackrightinput{
    flex-basis: 68%;
    margin-top:7px;
    margin-bottom:7px;
    padding-right:20px;
  }

  @media only screen and (min-width: 10px) and (max-width: 479px){

    :host ::ng-deep .ui-dialog {
      width: 100% !important;
    }

    .formcontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
    }

    .formitemlabel{
      flex-basis: 100%;
      margin-top:7px;
      margin-bottom:1px;
    }

    .formiteminput{
      flex-basis: 100%;
      margin-bottom:7px;
      margin-top:1px;
      padding-right:5px;
    }

    .viewstackright{
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
    }


    .viewstackrightinput{
      padding-right:5px;
    }

  }

  @media only screen and (min-width: 480px) and (max-width: 767px){

    :host ::ng-deep .ui-dialog {
      width: 100% !important;
    }

    .formitemlabel{
      flex-basis: 32%;
      margin-top:7px;
      margin-bottom:7px;
    }

    .formiteminput{
      flex-basis: 68%;
      margin-top:7px;
      margin-bottom:7px;
      padding-right:10px;
    }

    .viewstackright{
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    .viewstackrightinput{
      padding-right:10px;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 991px){


  }

  @media only screen and (min-width: 992px) and (max-width: 1999px){


  }

  `]
})
export class UserAdminFormComponent implements OnInit, OnChanges {
  public useradmindata : any[];
  public loading : boolean = false;
  @Input() displayaddedit : boolean = false;
  public formData: any;
  @Input() formactivemode: String;
  @Input() formactiveid: String;
  @Output() formClosed = new EventEmitter();
  public formLabel_header: string = "";
  public formLabel_button: string = "";
  items: MenuItem[];
  securities: SelectItem[];
  approvaluser: SelectItem[];
  activearr: SelectItem[];
  homepagearr: SelectItem[];

  
      public systemfilterarr: SelectItem[];

  handleprojectsSelected(projectsid) {
    this.formData.tab_project = projectsid;
    //console.log(this.formData);
  }

  constructor(private _UserAdminService:UserAdminService, public datepipe: DatePipe, private messageService: MessageService
  ) {
    //this.selecteduseradmin.tab_id = '';

    this.securities = [];

    this.securities.push({label:'', value:''});
    this.securities.push({label:'Full Access', value:'Full Access'});
    this.securities.push({label:'Modify/Delete', value:'Modify/Delete'});
    this.securities.push({label:'Modify', value:'Modify'});
    this.securities.push({label:'Read Only', value:'Read Only'});
    this.securities.push({label:'No Access', value:'No Access'});

    this.approvaluser = [];

    this.approvaluser.push({label:'', value:''});
    this.approvaluser.push({label:'yes', value:'yes'});

    this.activearr = [];

    this.activearr.push({label:'Inactive', value:'0'});
    this.activearr.push({label:'Active', value:'1'});

    this.homepagearr = [];
    this.homepagearr.push({label:'', value:''});

        this.homepagearr  .push({label:'Inventory Dashboard', value:'InvDashboard'});
        this.homepagearr  .push({label:'Order entry', value:'OrderEntry'});
        this.homepagearr  .push({label:'Stock transaction log', value:'StockTransactionLog'});


      this.systemfilterarr = [];
      this.systemfilterarr.push({label: '', value: ''});
      this.systemfilterarr.push({label: 'West service road', value: 'West service road'});
      this.systemfilterarr.push({label: 'Cotton lane', value: 'Cotton lane'});

  }

  ngOnInit() {
    this.formData = [];


  }

  ngOnChanges(){
    this.formshow(this.formactivemode, this.formactiveid);
  }

  formshow(mode, id = null){
    this.formData = [];

    this.formactivemode = mode;
    this.formactiveid = id;

    //alert(mode);

    if(mode == 'modify'){
      this.formLabel_header = 'Modify';
      this.formLabel_button = 'Save Modification';
    }
    else if(mode == 'copy' || mode == 'create'){
      this.formLabel_header = 'Create';
      this.formLabel_button = 'Create Record';
    }

    this.formData.changepassword = false;

    console.log(this.formData);

    if(mode == 'modify' || mode == 'copy'){
      setTimeout(() => {
        this.loading = true;
      }, 1000);
      this._UserAdminService.getuseradminSingleData(id).subscribe((data:any) => {
        data[0].password = '';
        this.formData = data[0];
        this.displayaddedit = true;
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        console.log(this.formData);

        if(mode == 'copy'){
          this.formData.changepassword = true;
        }

        this._UserAdminService.getisloggedobs();
      },
      error => {
        console.log(error); // gives the object object
      });
    }
    else{
      this.formData = [];
      this.formData.isactive = 1;
      this.formData.homepage = 'InvDashboard';

      this.formData.sec_admin = 'No Access';
      this.formData.sec_orderentry = 'No Access';
      this.formData.sec_transactionlog = 'No Access';
      this.displayaddedit = true;
      this.formData.changepassword = true;

      console.log('this.formData');
    }
  }

  formclose(){
    this.displayaddedit = false;
    this.formClosed.emit('close');
  }

  formsubmit(){
    if(this.formactivemode == 'modify'){
      //alert('modify');
      this.formData.id = this.formactiveid;

      setTimeout(() => {
        this.loading = true;
      }, 1000);
      this._UserAdminService.updateuseradminData(this.formData).subscribe((data : any) => {

        if(data.error){
          //alert(data.message);
          this.messageService.add({key: 'maintoast', severity:'error', summary:'User Admin Message', detail:data.error.text});

        }
        else{
          this.messageService.add({key: 'maintoast', severity:'success', summary:'User Admin Message', detail:'Account modified with success'});
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          //console.log(this.formData);
          //console.log(data);
          this.formclose();
        }
      },
      error => {
        this.messageService.add({key: 'maintoast', severity:'error', summary:'User Admin Message', detail: 'Error modifying record'});
        //console.log(this.formData);
        //console.log(error);
        this.formclose();
      });
    }
    else if(this.formactivemode == 'create' || this.formactivemode == 'copy'){
      //alert('modify');
      this.formData.id = this.formactiveid;
      this.formData.repeatpassword = this.formData.password;

      setTimeout(() => {
        this.loading = true;
      }, 1000);
      this._UserAdminService.adduseradminData(this.formData).subscribe((data : any) => {
        console.log(data);
        if(data.error == true){
          //alert(data.message);
          this.messageService.add({key: 'maintoast', severity:'error', summary:'User Admin Message', detail: data.message});
        }
        else{
          this.messageService.add({key: 'maintoast', severity:'success', summary:'User Admin Message', detail: data.message});
          this.formclose();
        }

        setTimeout(() => {
          this.loading = false;
        }, 1000);
      },
      error => {
        this.messageService.add({key: 'maintoast', severity:'error', summary:'User Admin Message', detail: 'Error adding record'});
        console.log(this.formData);
        console.log(error);
        this.formclose();
      });
    }
  }



}