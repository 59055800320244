import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy} from '@angular/core';
import { OrderEntryService } from './OrderEntry.service';
import {SelectItem, Message, MenuItem, OverlayPanel, ConfirmationService, LazyLoadEvent} from 'primeng/primeng';
import {DatePipe} from '@angular/common';
import { AppConfigService } from '../appconfig/appconfig.service';
import { UserAdminService } from '../useradmin/useradmin.service';
import { Subscription } from 'rxjs/Subscription';
import { interval } from 'rxjs/observable/interval';
import { timer } from 'rxjs/observable/timer';
import {BreadcrumbService} from '../breadcrumb.service';
import {MessageService} from 'primeng/api';

import {ediorderheaderService} from '../ediorderheader/ediorderheader.service';

@Component({
  selector: 'OrderEntry',
  providers: [OrderEntryService, DatePipe, ConfirmationService,ediorderheaderService],
  templateUrl: './OrderEntry.component.html',
  styles: [`

  :host ::ng-deep .ui-dialog {
    width: 98% !important;
    max-width: 1100px !important;
  }

  :host ::ng-deep .ui-message-info{
    background-color: #77bae8;
  }

  :host ::ng-deep .ui-message-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-messages-error{
    background-color: #fcb6bc;
  }

  :host ::ng-deep .ui-message-success{
    background-color: #b2d7b8;
  }

  :host ::ng-deep .ui-table-auto-layout > .ui-table-wrapper{
    overflow-x:visible;
  }

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .container h2 {
    display: block;
    flex-basis: 100%;
  }

  .itemfulllength{
    flex-basis: 100%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .headeritemleft{
    flex-basis: 35%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .headeritemmiddle{
    flex-basis: 30%;
    font-size:12pt;
    text-align: center;
  }

  .headeritemright{
    flex-basis: 35%;
    font-size:14pt;
    display: flex;
    justify-content: flex-end;
  }

  .headeritemrightbutton{
    margin-left:10px;
  }

  .filtercontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .filteritem{
    flex-basis: 16%;
    margin-top:7px;
    margin-bottom:7px;
  }

  .filterfooter{
    flex-basis: 100%;
    text-align:right;
    margin-top:7px;
    margin-bottom:7px;
  }

  @media only screen and (min-width: 10px) and (max-width: 479px){

    .filtercontainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
    }

    .filteritem{
      flex-basis: 100%;
      margin-top:7px;
      margin-bottom:1px;
    }

    .headeritemmiddle{
      flex-basis: 0%;
      display:none;
    }

    .headeritemleft{
      flex-basis: 50%;
    }
    
    .headeritemright{
      flex-basis: 50%;
    }

  }

  @media only screen and (min-width: 480px) and (max-width: 767px){


    .filteritem{
      flex-basis: 32%;
      margin-top:7px;
      margin-bottom:7px;
    }

    .headeritemmiddle{
      flex-basis: 0%;
      display:none;
    }

    .headeritemleft{
      flex-basis: 50%;
    }
    
    .headeritemright{
      flex-basis: 50%;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 1200px){

    .filteritem{
      flex-basis: 25%;
      margin-top:7px;
      margin-bottom:7px;
    }

  }

  @media only screen and (min-width: 992px) and (max-width: 1999px){


  }


  `]
})
export class OrderEntryComponent implements OnInit {
  public OrderEntrydata : any = [];
  @Input() defaultcreatevalues:any = [];
  @Output() ediorderheaderAdded = new EventEmitter();
   public loading : boolean = false;
  public msgs: Message[] = [];
  public totalRecords: any;


    public formactivemode: String;
    public formactiveid: String;
    public splitbuttonid: String;
    public splitbuttonstatus: String;
    public displayaddedit : boolean = false;
    @ViewChild('orderentryform', {static: false}) OrderEntryFormComponent;
    splitbuttonitems: MenuItem[];

  

  public userinfo: any = [];
  public appconfig: any = [];

  subscriptionuserinfo: Subscription;
  subscriptionappconfig: Subscription;

  public filterData: any = [];
  public filterDataShow : any = [];
  
    @Input() filterclientref : String;
    @Input() filtershiptoref : String;
    @Input() filterstatus : String;
    @Input() filtershiptoname : String;
  
  
  

  @ViewChild('dt', {static: false}) myTable: any;

  constructor(private _OrderEntryService : OrderEntryService, private _ediorderheaderService:ediorderheaderService, public datepipe: DatePipe, private _UserAdminService : UserAdminService, private _AppConfigService: AppConfigService, private _ConfirmationService:ConfirmationService, private breadcrumbService: BreadcrumbService, private messageService: MessageService) {

    this.breadcrumbService.setItems([
      {label: 'blportal'},
      {label: 'Order entry', routerLink: ['/OrderEntry']}
    ]);

    
    this.splitbuttonitems = [
      {label: 'Copy', icon: 'fa fa-refresh', command: () => {
          this.formshow('copy', this.splitbuttonid);
      }},
      {label: 'Delete', icon: 'fa fa-close', command: () => {
          this.delete(this.splitbuttonid);
      }}
    ];
    
  }
  
  ngOnInit() {
    
    this.filterData.lazy_first = 1;
    this.filterData.lazy_rows = 10;
    this.filterData.lazy_sortField = 'eoh_id';
    this.filterData.lazy_sortOrder = 1;
    this.totalRecords = 0;
    
      this.filterData.eoh_clientref = '';
      this.filterData.eoh_shiptoref = '';
      this.filterData.eoh_status = '';
      this.filterData.eoh_shiptoname = '';
    

    this.subscriptionuserinfo = this._UserAdminService.isloggedobs().subscribe(
      userinfo => {
        this.userinfo = userinfo;

        //console.log(userinfo);
      }
    );

    this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
      appconfig => {
        this.appconfig = appconfig[0];
        //console.log(this.appconfig);
      }
    );

    this.getediorderheaderData();

  }

  
  
    setsplitbuttonid(id){
      this.splitbuttonid = id;
    }

    formshow(mode, id = null){
      this.formactivemode = mode;
      this.formactiveid = id;
      this.displayaddedit = true;

      this.OrderEntryFormComponent.formshow(mode, id);

    }

    formclose(){
      this.displayaddedit = false;


    }

    handleformClosed(id){
      this.formclose();
      this.getediorderheaderData();
    }

    delete(id){

      if(this.splitbuttonstatus == 'Hold - waiting for user approval'){
        this._ConfirmationService.confirm({
          message: 'Do you want to delete this record?',
          header: 'Delete Confirmation',
          icon: 'fa fa-trash',
          accept: () => {
            this.loading = true;
            this._ediorderheaderService.deleteediorderheaderData(id).subscribe(data => {
              this.msgs = [];
              this.msgs.push({severity: 'success', summary: 'Comment Tag Message', detail: 'Record deleted with success'});
              setTimeout(() => {
                this.loading = false;
              }, 500);
              console.log(data);
              this.getediorderheaderData();
            },
            error => {
              this.msgs = [];
              this.msgs.push({severity: 'error', summary: 'Comment Tag Message', detail: 'Error deleting record'});
              console.log(error);
            });
          }
        });
      }
      else{
        alert('This order cannot be deleted as it\'s currently being processed, please contact Beeline directly')
      }

    }

    

  getediorderheaderData() {
    //console.log(this.filterData);
    setTimeout(() => {
      this.msgs = [];
      this.msgs.push({severity: 'info', summary: 'Order entry Message', detail: 'Loading Data'});
      this.loading = true;
    }, 100);
    this._OrderEntryService.getOrderEntryData(this.filterData).subscribe(data => {
      if(data){
        this.OrderEntrydata = data;
        this.messageService.add({key: 'maintoast', severity:'success', summary:'Order entry Message', detail:'Data loaded with success'});
      }
      else{
        this.OrderEntrydata = [];
        this.messageService.add({key: 'maintoast', severity:'error', summary:'Order entry Message', detail:'No record found'});
      }
      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

  secondbeforerefresh = 0;
  subscribe;
  source;

  filterrefresh(second = 3){

    //this.dataTable.filter(event.target.value, field, 'contains');

    this.filterData.eoh_orderdate_startstring = this.datepipe.transform(this.filterData.eoh_orderdate_start, 'yyyy-MM-dd');
    this.filterData.eoh_orderdate_endstring = this.datepipe.transform(this.filterData.eoh_orderdate_end, 'yyyy-MM-dd');
    

    //alert('filter refresh');
    //emit value in sequence every 1 second
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    this.secondbeforerefresh = second;

    this.source = interval(1000);
    //output: 0,1,2,3,4,5....
    this.subscribe = this.source.subscribe(val => {
      if(this.secondbeforerefresh > 0){
        this.secondbeforerefresh = this.secondbeforerefresh - 1;
      }
      if(this.secondbeforerefresh == 0){
        this.subscribe.unsubscribe();

      this.filterData.lazy_first = 0;
      console.log(this.filterData);
      //this.getediorderheaderData();

      this._ediorderheaderService.getediorderheaderNumData(this.filterData).subscribe(data => {
        this.totalRecords = data[0]['countrow'];
        this.getediorderheaderData();
        this.myTable.first = 0;
        //console.log(data);
      },
      error => {
        console.log(error); // gives the object object
      });
      }
    });
  }

  resetfilter(){
    
      this.filterData.eoh_clientref = '';
      this.filterData.eoh_shiptoref = '';
      this.filterData.eoh_status = '';
      this.filterData.eoh_shiptoname = '';
      this.filterData.eoh_orderdate_startstring = '';
      this.filterData.eoh_orderdate_start = null;
      this.filterData.eoh_orderdate_endstring = '';
      this.filterData.eoh_orderdate_end = null;

      this.filterData.lazy_first = 0;
      console.log(this.filterData);
      //this.getediorderheaderData();

      this._ediorderheaderService.getediorderheaderNumData(this.filterData).subscribe(data => {
        this.totalRecords = data[0]['countrow'];
        this.getediorderheaderData();
        this.myTable.first = 0;
        //console.log(data);
      },
      error => {
        console.log(error); // gives the object object
      });
  }


  
    loadediorderheaderLazy(event: LazyLoadEvent) {
      //alert('lazy');
      this.filterData.lazy_first = event.first;
      this.filterData.lazy_rows = event.rows;
      this.filterData.lazy_sortField = event.sortField;
      this.filterData.lazy_sortOrder = event.sortOrder;
      this.loading = true;

      this._OrderEntryService.getOrderEntryNumData(this.filterData).subscribe(data => {
        this.totalRecords = data[0]['countrow'];
        this.getediorderheaderData();

        //console.log(data);
      },
      error => {
        console.log(error); // gives the object object
      });

    }
  
}
