import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy} from '@angular/core';
import { InvDashboardService } from './InvDashboard.service';
import {SelectItem, Message, MenuItem, OverlayPanel, ConfirmationService, LazyLoadEvent} from 'primeng/primeng';
import {DatePipe} from '@angular/common';
import { AppConfigService } from '../appconfig/appconfig.service';
import { UserAdminService } from '../useradmin/useradmin.service';
import { Subscription } from 'rxjs/Subscription';
import {portalinventoryService} from '../portalinventory/portalinventory.service';
import {BreadcrumbService} from '../breadcrumb.service';

@Component({
  selector: 'InvDashboard',
  providers: [InvDashboardService, portalinventoryService, DatePipe, ConfirmationService],
  templateUrl: './InvDashboard.component.html'
})
export class InvDashboardComponent implements OnInit {
  public InvDashboarddata : any = [];
  @Input() defaultcreatevalues:any = [];
  @Input() showfilter : boolean = true;
  @Output() portalinventoryAdded = new EventEmitter();
  public loading : boolean = false;
  public displayaddedit : boolean = false;
  public msgs: Message[] = [];
  public formData: any;
  public formactivemode: String;
  public formactiveid: String;
  public splitbuttonid: String;
  public userinfo: any;
  public appconfig: any;

  @ViewChild('portalinventoryform', {static: false}) portalinventoryFormComponent;

  subscriptionislogged: Subscription;
  subscriptionappconfig: Subscription;

  public filterData: any = [];

  constructor(private _InvDashboardService : InvDashboardService, private _portalinventoryService:portalinventoryService, public datepipe: DatePipe, private _UserAdminService : UserAdminService, private _AppConfigService: AppConfigService ,  private breadcrumbService: BreadcrumbService, private _ConfirmationService:ConfirmationService) {
    this.formData = [];
    this.userinfo = [];
    this.appconfig = [];
    this.breadcrumbService.setItems([
      {label: 'blportal'},
      {label: 'Inventory dashboard', routerLink: ['/InvDashboard']}
    ]);


  }

  ngOnInit() {
    this.formData = [];

    this.filterData.pinv_cukey = '';
    this.filterData.pinv_prodcode = '';
    this.filterData.pinv_proddesc = '';
    this.filterData.pinv_lot = '';
    this.filterData.pinv_proddate = '';
    this.filterData.pinv_qty = '';
    this.filterData.pinv_uom = '';
    this.filterData.pinv_bin = '';
    this.filterData.pinv_whse = '';
    this.filterData.pinv_address = '';
    this.filterData.pinv_city = '';
    this.filterData.pinv_provstate = '';
    this.filterData.pinv_country = '';

    this.subscriptionislogged = this._UserAdminService.isloggedobs().subscribe(
      userinfo => {
        this.userinfo = userinfo;

        console.log(userinfo);
      }
    );

    this._UserAdminService.refreshisloggedobs();

    this.subscriptionappconfig = this._AppConfigService.getappconfigdataobs().subscribe(
      appconfig => {
      this.appconfig = appconfig[0];
      console.log(this.appconfig);
      }
    );

    this._AppConfigService.refreshconfigdataobs();

    this.getportalinventoryData();
  }

  getportalinventoryData() {
    console.log(this.filterData);
    setTimeout(() => {
      this.msgs = [];
      this.msgs.push({severity: 'info', summary: 'Inventory Dashboard Message', detail: 'Loading Data'});
      this.loading = true;
    }, 100);
    this._InvDashboardService.getInvDashboardData(this.filterData).subscribe(data => {
      this.InvDashboarddata = data['inventorydata'];
      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }


  excelexport() {

    window.open("http://customers.beelinelogistics.com/ws/report_customer_inventory.php");
    //window.open("http://blportalwebservicestest/report_customer_inventory.php");

  }


}
