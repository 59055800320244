import {Injectable} from '@angular/core';
//import {Http, Headers, URLSearchParams} from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class accountconfigService {
  public result: any;

  constructor(private http: HttpClient){
      //console.log('Account config Service Initialized...');
  }

  getaccountconfigData(filterData){
    let data = new HttpParams()
    .set('lazy_first', filterData.lazy_first)
    .set('lazy_rows', filterData.lazy_rows)
    .set('lazy_sortField', filterData.lazy_sortField)
    .set('lazy_sortOrder', filterData.lazy_sortOrder)
    
        .set('aconf_cukey', filterData.aconf_cukey)
        .set('aconf_username', filterData.aconf_username)
        .set('aconf_companyname', filterData.aconf_companyname);

    return this.http.post('/accountconfig_getall', data, { withCredentials: true })
    .catch(err =>  {
      console.log(err);
      return Observable.throw(err);
    });
  }

  getaccountconfigNumData(filterData){
    let data = new HttpParams()

        .set('aconf_cukey', filterData.aconf_cukey)
        .set('aconf_username', filterData.aconf_username)
        .set('aconf_companyname', filterData.aconf_companyname);
    return this.http.post('/accountconfig_getnum', data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  getaccountconfigSingleData(id){
    let data = new HttpParams();
    return this.http.get('/accountconfig_getsingle/' + id, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }
  addaccountconfigData(formData){
    let data = new HttpParams()
    
        .set('aconf_cukey', formData.aconf_cukey)
        .set('aconf_username', formData.aconf_username)
        .set('aconf_companyname', formData.aconf_companyname);
    return this.http.post<any>('/accountconfig_add', data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  updateaccountconfigData(formData){
    let data = new HttpParams()

        .set('aconf_cukey', formData.aconf_cukey)
        .set('aconf_username', formData.aconf_username)
        .set('aconf_companyname', formData.aconf_companyname);
    return this.http.put('/accountconfig_update/' + formData.id, data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  deleteaccountconfigData(id){
    return this.http.delete('/accountconfig_delete/' + id, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }
  getaccountconfigParrentData(extraquerystring){
      let data = new HttpParams()
      .set('extraquerystring', extraquerystring);

      return this.http.post('/accountconfig_getparrent', data, { withCredentials: true })
      .catch(err =>  {
        return Observable.throw(err);
      });
  }
}
