import {Injectable} from '@angular/core';
//import {Http, Headers, URLSearchParams} from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class ediorderheaderService {
  public result: any;

  constructor(private http: HttpClient){
      //console.log('EDI Order Header Service Initialized...');
  }

  getediorderheaderData(filterData){
    let data = new HttpParams()
    .set('lazy_first', filterData.lazy_first)
    .set('lazy_rows', filterData.lazy_rows)
    .set('lazy_sortField', filterData.lazy_sortField)
    .set('lazy_sortOrder', filterData.lazy_sortOrder)
    
        .set('eoh_clientref', filterData.eoh_clientref)
        .set('eoh_shiptoref', filterData.eoh_shiptoref)
        .set('eoh_status', filterData.eoh_status)
        .set('eoh_shiptoname', filterData.eoh_shiptoname);

    return this.http.post('/ediorderheader_getall', data, { withCredentials: true })
    .catch(err =>  {
      console.log(err);
      return Observable.throw(err);
    });
  }

  getediorderheaderNumData(filterData){
    let data = new HttpParams()

        .set('eoh_clientref', filterData.eoh_clientref)
        .set('eoh_shiptoref', filterData.eoh_shiptoref)
        .set('eoh_status', filterData.eoh_status)
        .set('eoh_shiptoname', filterData.eoh_shiptoname);
    return this.http.post('/ediorderheader_getnum', data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  getediorderheaderSingleData(id){
    let data = new HttpParams();
    return this.http.get('/ediorderheader_getsingle/' + id, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }
  addediorderheaderData(formData){
    let data = new HttpParams()
    
        .set('eoh_clientref', formData.eoh_clientref)
        .set('eoh_shiptoref', formData.eoh_shiptoref)
        .set('eoh_status', formData.eoh_status)
        .set('eoh_shiptoname', formData.eoh_shiptoname)
        .set('eoh_shiptoaddress1', formData.eoh_shiptoaddress1)
        .set('eoh_shiptoaddress2', formData.eoh_shiptoaddress2)
        .set('eoh_shiptocity', formData.eoh_shiptocity)
        .set('eoh_shiptostate', formData.eoh_shiptostate)
        .set('eoh_shiptozipcode', formData.eoh_shiptozipcode)
        .set('eoh_shiptocountry', formData.eoh_shiptocountry);
    return this.http.post<any>('/ediorderheader_add', data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  updateediorderheaderData(formData){
    let data = new HttpParams()

        .set('eoh_clientref', formData.eoh_clientref)
        .set('eoh_shiptoref', formData.eoh_shiptoref)
        .set('eoh_status', formData.eoh_status)
        .set('eoh_shiptoname', formData.eoh_shiptoname)
        .set('eoh_shiptoaddress1', formData.eoh_shiptoaddress1)
        .set('eoh_shiptoaddress2', formData.eoh_shiptoaddress2)
        .set('eoh_shiptocity', formData.eoh_shiptocity)
        .set('eoh_shiptostate', formData.eoh_shiptostate)
        .set('eoh_shiptozipcode', formData.eoh_shiptozipcode)
        .set('eoh_shiptocountry', formData.eoh_shiptocountry);
    return this.http.put('/ediorderheader_update/' + formData.id, data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  deleteediorderheaderData(id){
    return this.http.delete('/ediorderheader_delete/' + id, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }
}
