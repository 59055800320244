import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAdminService } from '../useradmin/useradmin.service';
import {Message} from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'useradminresetpass',
  providers: [UserAdminService],
  templateUrl: './useradminresetpass.component.html'
})
export class UserAdminResetPassComponent implements OnInit, OnDestroy {
  public formData: any;
  public formmode: String;
  public routeparams: any;
  public requestdata: any;
  public useradminchangepassdata: any;
  public loading : boolean = false;
  public msgs: Message[] = [];

  constructor(private _UserAdminService : UserAdminService, private route: ActivatedRoute, private router: Router) {
    this.formData = [];
    this.formmode = 'Change';
  }

  ngOnInit() {
    this.routeparams = this.route.params.subscribe(params => {
       this.formData.key = params['key']; // (+) converts string 'id' to a number

       // In a real app: dispatch action to load the details here.
    });
    this.getrequestfromkey();
  }

  getrequestfromkey() {

    setTimeout(() => {
      this.loading = true;
    }, 100);
    this._UserAdminService.getrequest(this.formData.key, 'reset').subscribe(data => {
      this.requestdata = data;

      this.formData.email = data.email;

      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });

  }


  ngOnDestroy() {
    this.routeparams.unsubscribe();
  }

  gotologin() {
    this.router.navigate(['/login']);
  }

  resetpassword(){
    setTimeout(() => {
      this.loading = true;
    }, 100);
    this._UserAdminService.resetpass(this.formData).subscribe(data => {
      this.useradminchangepassdata = data;

      if(this.useradminchangepassdata.error == true){
        this.msgs.push({severity: 'error', summary: 'User Admin - Reset Password Message', detail: this.useradminchangepassdata.message});
      }
      else{
        this.msgs.push({severity: 'success', summary: 'User Admin - Reset Password Message', detail: this.useradminchangepassdata.message});
        this.formmode = 'Changed';
      }

      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

}
