import { Component, OnInit } from '@angular/core';
import {Message} from 'primeng/primeng';
import { UserAdminService } from '../useradmin/useradmin.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'useradminreqresetpass',
  providers: [UserAdminService],
  templateUrl: './useradminreqresetpass.component.html'
})
export class UserAdminReqResetPassComponent implements OnInit {
  public formData: any;
  public formmode: String;
  public useradminreqresetpassdata: any;
  public loading : boolean = false;
  public msgs: Message[] = [];

  constructor(private _UserAdminService : UserAdminService, private route: ActivatedRoute, private router: Router ) {
    this.formData = [];
    this.formmode = 'Request';
  }

  ngOnInit() {
    this.islogged();
  }

  islogged(){
    this._UserAdminService.islogged().subscribe(data => {
      this.formData = data;

      if(!data.islogged){
        this.router.navigate(['/login']);
      }

      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }


  reqresetpassword(){

    this.loading = true;

    this._UserAdminService.requestresetpass(this.formData).subscribe(data => {
      this.useradminreqresetpassdata = data;

      if(this.useradminreqresetpassdata.error == true){
        this.msgs.push({severity: 'error', summary: 'Request Reset Password Message', detail: this.useradminreqresetpassdata.message});
      }
      else{
        this.msgs.push({severity: 'success', summary: 'Request Reset Password Message', detail: this.useradminreqresetpassdata.message});
        this.formmode = 'Requested';
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

}
