
import {Routes, RouterModule, CanActivate } from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {DashboardDemo} from './demo/view/dashboarddemo';
import { AuthGuard } from './_guards/auth.guard';

import { UserAdminFormComponent} from './useradmin/form-useradmin.component';
//CUSTOM MODULE IMPORT

        import { InvDashboardComponent } from './InvDashboard/InvDashboard.component';
          import { InvDashboardService } from './InvDashboard/InvDashboard.service';
        import { OrderEntryComponent } from './OrderEntry/OrderEntry.component';
          import { OrderEntryService } from './OrderEntry/OrderEntry.service';
  import { portalinventoryComponent } from './portalinventory/datalist-portalinventory.component'; //MENU DATALIST
  import { portalinventoryFormComponent } from './portalinventory/form-portalinventory.component'; //MENU FORM
          import { portalinventoryService } from './portalinventory/portalinventory.service';
  import { accountconfigComponent } from './accountconfig/datalist-accountconfig.component'; //MENU DATALIST
  import { accountconfigFormComponent } from './accountconfig/form-accountconfig.component'; //MENU FORM
          import { accountconfigService } from './accountconfig/accountconfig.service';
        import { TransactionLogComponent } from './StockTransactionLog/StockTransactionLog.component';
          import { TransactionLogService } from './StockTransactionLog/StockTransactionLog.service';
  import { portalitemmasterComponent } from './portalitemmaster/datalist-portalitemmaster.component'; //MENU DATALIST
  import { portalitemmasterFormComponent } from './portalitemmaster/form-portalitemmaster.component'; //MENU FORM
          import { portalitemmasterService } from './portalitemmaster/portalitemmaster.service';
  import { ediorderheaderComponent } from './ediorderheader/datalist-ediorderheader.component'; //MENU DATALIST
  import { ediorderheaderFormComponent } from './ediorderheader/form-ediorderheader.component'; //MENU FORM
          import { ediorderheaderService } from './ediorderheader/ediorderheader.service';
        import { AppConfigComponent } from './appconfig/appconfig.component';
          import { AppConfigService } from './appconfig/appconfig.service';
        import { UserAdminComponent } from './useradmin/useradmin.component';
          import { UserAdminService } from './useradmin/useradmin.service';
        import { LoginComponent } from './login/login.component';
        import { UserAdminActComponent } from './useradminactivate/useradminactivate.component';
        import { UserAdminResetPassComponent } from './useradminresetpass/useradminresetpass.component';
        import { UserAdminReqResetPassComponent } from './useradminreqresetpass/useradminreqresetpass.component';
        import { MyProfileComponent } from './myprofile/myprofile.component';



export const routes: Routes = [
  {path: '', component: InvDashboardComponent, canActivate: [AuthGuard], data: { secneeded: '', seclevelneeded: ''}},

    {path: 'InvDashboard', component: InvDashboardComponent  },
    {path: 'OrderEntry', component: OrderEntryComponent , canActivate: [AuthGuard], data: { secneeded: 'orderentry', seclevelneeded: 'fullaccess'} },
            {path: 'portalinventory', component: portalinventoryComponent  },
            {path: 'accountconfig', component: accountconfigComponent  },
    {path: 'StockTransactionLog', component: TransactionLogComponent , canActivate: [AuthGuard], data: { secneeded: 'transactionlog', seclevelneeded: 'fullaccess'} },
            {path: 'portalitemmaster', component: portalitemmasterComponent  },
            {path: 'ediorderheader', component: ediorderheaderComponent  },
    {path: 'appconfig', component: AppConfigComponent  },
    {path: 'useradmin', component: UserAdminComponent , canActivate: [AuthGuard], data: { secneeded: 'admin', seclevelneeded: 'read'} },
    {path: 'login', component: LoginComponent  },
    {path: 'useradminactivate', component: UserAdminActComponent  },
  {path: 'useradminactivate/:key', component: UserAdminActComponent  },
    {path: 'useradminresetpass', component: UserAdminResetPassComponent  },
  {path: 'useradminresetpass/:key', component: UserAdminResetPassComponent  },
    {path: 'useradminreqresetpass', component: UserAdminReqResetPassComponent  },
    {path: 'myprofile', component: MyProfileComponent  },


];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);