import { Component, OnInit } from '@angular/core';
import {Message} from 'primeng/primeng';
import { UserAdminService } from '../useradmin/useradmin.service';
@Component({
  selector: 'myprofile',
  providers: [UserAdminService],
  templateUrl: './myprofile.component.html'
})
export class MyProfileComponent implements OnInit {
  public formData: any;
  public myprofiledata: any;
  public loading : boolean = false;
  public msgs: Message[] = [];

  constructor(private _UserAdminService : UserAdminService) {
    this.formData = [];
    this.formData.id = '1';
  }

  ngOnInit() {
    //this.loaddata();
  }

  loaddata(){
    setTimeout(() => {
      this.msgs.push({severity: 'info', summary: 'My Profile Message', detail: 'Loading Data'});
      this.loading = true;
    }, 100);
    this._UserAdminService.logout().subscribe(data => {
      this.myprofiledata = data;
      setTimeout(() => {
        this.loading = false;
      }, 100);
      console.log(data);
    },
    error => {
      console.log(error); // gives the object object
    });
  }

}
