import {Injectable} from '@angular/core';
    import { HttpClient, HttpParams } from '@angular/common/http';
    import 'rxjs/add/operator/map';
    import {Observable} from 'rxjs/Rx';

    @Injectable()
    export class TransactionLogService {
      public result: any;

      constructor(private http: HttpClient){
          //console.log('TransactionLogService Initialized...');
      }
      getStockTransactionLogData(filterData){
        let data = new HttpParams()
        
        
        .set('tlg_transdate', filterData.tlg_transdate)
        .set('tlg_transtype', filterData.tlg_transtype)
        .set('tlg_transqty', filterData.tlg_transqty)
        .set('tlg_transuom', filterData.tlg_transuom)
        .set('tlg_prodcode', filterData.tlg_prodcode)
        .set('tlg_proddesc', filterData.tlg_proddesc)
        .set('tlg_blref', filterData.tlg_blref)
        .set('tlg_custref', filterData.tlg_custref);

        return this.http.post('/StockTransactionLog_getall', data, { withCredentials: true })
        .catch(err =>  {
          console.log(err);
          return Observable.throw(err); // observable needs to be returned or exception raised
        });
      }

      getStockTransactionLogNumData(filterData){
        let data = new HttpParams()

        .set('tlg_transdate', filterData.tlg_transdate)
        .set('tlg_transtype', filterData.tlg_transtype)
        .set('tlg_transqty', filterData.tlg_transqty)
        .set('tlg_transuom', filterData.tlg_transuom)
        .set('tlg_prodcode', filterData.tlg_prodcode)
        .set('tlg_proddesc', filterData.tlg_proddesc)
        .set('tlg_blref', filterData.tlg_blref)
        .set('tlg_custref', filterData.tlg_custref);

        return this.http.post('/StockTransactionLog_getnum', data, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err); // observable needs to be returned or exception raised
        });
      }

      getStockTransactionLogSingleData(id){
        let data = new HttpParams();

        return this.http.get('/StockTransactionLog_getsingle/' + id, { withCredentials: true })
        .catch(err =>  {
          return Observable.throw(err); // observable needs to be returned or exception raised
        });
      }



  }