import {Injectable} from '@angular/core';
//import {Http, Headers, URLSearchParams} from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class portalinventoryService {
  public result: any;

  constructor(private http: HttpClient){
      //console.log('BeeLine portal inventory Service Initialized...');
  }

  getportalinventoryData(filterData){
    let data = new HttpParams()
    .set('lazy_first', filterData.lazy_first)
    .set('lazy_rows', filterData.lazy_rows)
    .set('lazy_sortField', filterData.lazy_sortField)
    .set('lazy_sortOrder', filterData.lazy_sortOrder)
    
        .set('pinv_cukey', filterData.pinv_cukey)
        .set('pinv_system', filterData.pinv_system)
        .set('pinv_prodcode', filterData.pinv_prodcode)
        .set('pinv_proddesc', filterData.pinv_proddesc)
        .set('pinv_lot', filterData.pinv_lot)
        .set('pinv_proddate', filterData.pinv_proddate)
        .set('pinv_qty', filterData.pinv_qty)
        .set('pinv_uom', filterData.pinv_uom)
        .set('pinv_whse', filterData.pinv_whse)
        .set('pinv_address', filterData.pinv_address)
        .set('pinv_city', filterData.pinv_city)
        .set('pinv_provstate', filterData.pinv_provstate)
        .set('pinv_postalcd', filterData.pinv_postalcd)
        .set('pinv_country', filterData.pinv_country);

    return this.http.post('/portalinventory_getall', data, { withCredentials: true })
    .catch(err =>  {
      console.log(err);
      return Observable.throw(err);
    });
  }

  getportalinventoryNumData(filterData){
    let data = new HttpParams()

        .set('pinv_cukey', filterData.pinv_cukey)
        .set('pinv_system', filterData.pinv_system)
        .set('pinv_prodcode', filterData.pinv_prodcode)
        .set('pinv_proddesc', filterData.pinv_proddesc)
        .set('pinv_lot', filterData.pinv_lot)
        .set('pinv_proddate', filterData.pinv_proddate)
        .set('pinv_qty', filterData.pinv_qty)
        .set('pinv_uom', filterData.pinv_uom)
        .set('pinv_whse', filterData.pinv_whse)
        .set('pinv_address', filterData.pinv_address)
        .set('pinv_city', filterData.pinv_city)
        .set('pinv_provstate', filterData.pinv_provstate)
        .set('pinv_postalcd', filterData.pinv_postalcd)
        .set('pinv_country', filterData.pinv_country);
    return this.http.post('/portalinventory_getnum', data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  getportalinventorySingleData(id){
    let data = new HttpParams();
    return this.http.get('/portalinventory_getsingle/' + id, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }
  addportalinventoryData(formData){
    let data = new HttpParams()
    
        .set('pinv_cukey', formData.pinv_cukey)
        .set('pinv_system', formData.pinv_system)
        .set('pinv_prodcode', formData.pinv_prodcode)
        .set('pinv_proddesc', formData.pinv_proddesc)
        .set('pinv_lot', formData.pinv_lot)
        .set('pinv_proddate', formData.pinv_proddate)
        .set('pinv_qty', formData.pinv_qty)
        .set('pinv_uom', formData.pinv_uom)
        .set('pinv_whse', formData.pinv_whse)
        .set('pinv_address', formData.pinv_address)
        .set('pinv_city', formData.pinv_city)
        .set('pinv_provstate', formData.pinv_provstate)
        .set('pinv_postalcd', formData.pinv_postalcd)
        .set('pinv_country', formData.pinv_country);
    return this.http.post<any>('/portalinventory_add', data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  updateportalinventoryData(formData){
    let data = new HttpParams()

        .set('pinv_cukey', formData.pinv_cukey)
        .set('pinv_system', formData.pinv_system)
        .set('pinv_prodcode', formData.pinv_prodcode)
        .set('pinv_proddesc', formData.pinv_proddesc)
        .set('pinv_lot', formData.pinv_lot)
        .set('pinv_proddate', formData.pinv_proddate)
        .set('pinv_qty', formData.pinv_qty)
        .set('pinv_uom', formData.pinv_uom)
        .set('pinv_whse', formData.pinv_whse)
        .set('pinv_address', formData.pinv_address)
        .set('pinv_city', formData.pinv_city)
        .set('pinv_provstate', formData.pinv_provstate)
        .set('pinv_postalcd', formData.pinv_postalcd)
        .set('pinv_country', formData.pinv_country);
    return this.http.put('/portalinventory_update/' + formData.id, data, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }

  deleteportalinventoryData(id){
    return this.http.delete('/portalinventory_delete/' + id, { withCredentials: true })
    .catch(err =>  {
      return Observable.throw(err);
    });
  }
  getportalinventoryParrentData(extraquerystring){
      let data = new HttpParams()
      .set('extraquerystring', extraquerystring);

      return this.http.post('/portalinventory_getparrent', data, { withCredentials: true })
      .catch(err =>  {
        return Observable.throw(err);
      });
  }
}
