import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import {accountconfigService} from './accountconfig.service';
import {Message, MenuItem, OverlayPanel, ConfirmationService} from 'primeng/primeng';

@Component({
  selector: 'menubuttonaccountconfig',
  providers: [accountconfigService, ConfirmationService],
  templateUrl: './menubutton-accountconfig.component.html',
  styles: [`

  `]
})
export class accountconfigMenuButtonComponent implements OnInit, OnChanges {
  @Output() FormSubmit = new EventEmitter();
  @Input() selectedaccountconfig = "";
  @Input() defaultcreatevalues:any = [];
  public loading : boolean = false;
  public error : boolean;
  private menuitems: MenuItem[];
  public displayaddedit : boolean = false;
  public msgs: Message[] = [];
  public formactivemode: String;
  public formactiveid: String;
  public formLabel_header: String = "";
  public formData:any = [];


  @ViewChild('accountconfigform', {static: false}) accountconfigFormComponent;

  constructor(private _accountconfigService:accountconfigService, private _ConfirmationService: ConfirmationService) {

    this.menuitems = [{
        label: 'Basic Action',
        items: [
            {label: 'New Account config', icon: 'fa-plus', command: (event) => {
                this.formshow('create', 0);
            }},
            {label: 'Modify Account config', icon: 'fa-download', command: (event) => {
                this.formshow('modify', this.selectedaccountconfig);
            }},
            {label: 'Copy Account config', icon: 'fa-download', command: (event) => {
                this.formshow('copy', this.selectedaccountconfig);
            }}
        ]
    },
    {
        label: 'More Action',
        items: [
            {label: 'Delete Account config', icon: 'fa-trash', command: (event) => {
                this.deleteaccountconfig(this.selectedaccountconfig);
            }}
        ]
    }];
  }

  handleformClosed(id){
    console.log('FormClosed', id);
    this.FormSubmit.emit(id);
    this.displayaddedit = false;
  }

  formshow(mode, id = null){
    if(mode == 'modify'){
      this.formLabel_header = 'Modify';
    }
    else if(mode == 'copy' || mode == 'create'){
      this.formLabel_header = 'Create';
    }

    this.formactivemode = mode;
    this.formactiveid = id;
    this.displayaddedit = true;

    this.accountconfigFormComponent.formshow(mode, id);
  }

  ngOnInit() {

  }

  ngOnChanges(...args: any[]) {
    //if(args[0]['extraquerystring'] != null){
      //console.log('refresh parrent');
      //this.getaccountconfigData();
    //}
  }

  deleteaccountconfig(id){
      this._ConfirmationService.confirm({
          message: 'Do you want to delete this record?',
          header: 'Delete Confirmation',
          icon: 'fa fa-trash',
          accept: () => {
            this.loading = true;
            this._accountconfigService.deleteaccountconfigData(id).subscribe(data => {
              this.msgs.push({severity: 'success', summary: 'Account config Message', detail: 'Account config deleted with success'});
              setTimeout(() => {
                this.loading = false;
              }, 500);
              console.log(data);
            },
            error => {
              this.msgs.push({severity: 'error', summary: 'Account config Message', detail: 'Error deleting record'});
              console.log(this.formData);
              console.log(error);
            });
          }
      });
    }

}